import { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { VitecNextOrderArrowBackIcon } from './Icons'
import { NoWrapFigmaText } from './StyledComponents'

export function VitecNextOrderProductBackButton({ vitecNextOrderId }: VitecNextOrderProductProps) {
  const navigate = useNavigate({ enablePreserveQueryParams: true })
  const onClickBack = () => navigate(formatRoutePathVitexNextOrder(vitecNextOrderId))
  return (
    <Box direction="row" link onClick={onClickBack}>
      <Box>
        <VitecNextOrderArrowBackIcon />
      </Box>
      <Box left spacing={smallSpacing}>
        <NoWrapFigmaText textKey={Texts.standardReturnToDashboard} />
      </Box>
    </Box>
  )
}

import JsonTexts from '../assets/texts.json'

export const Texts = JsonTexts

export const HardCodedTexts = {
  DescriptionsDOrderItemHeader: {
    characters: 'Beskrivelser',
    name: 'DescriptionsDOrderItemHeader',
    texts: {
      en: 'Inhold',
      no: 'Inhold'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: '-0.48px',
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  },
  orderViewHeader: {
    name: 'orderViewHeader',
    characters: 'Hjelms gate 2B, Oslo',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: '-0.64px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: '🖥️ Gazelle App Production',
    figmaTextKeyName: 'TXT_OrderViewHeader'
  },
  copyToClipBoard: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Copied to clipboard',
      no: 'Kopiert til clipboard'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  vitecNextOrderProductHeaderExperimentalText: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Go to Normal version',
      no: 'Go to Normal version'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  vitecNextOrderProductHeaderGoToExperimentalText: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Go to Next version',
      no: 'Go to Next version'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  BasicAssociationBaFeedbackAdditionalText: {
    characters: 'Velg tekster for spesifikk tilbakemelding.',
    name: 'BasicAssociationBaFeedbackAdditionalText',
    texts: {
      en: 'Select texts for specific feedback',
      no: 'Velg tekster for spesifikk tilbakemelding.'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(186, 186, 186, 1)'
    },
    pageName: 'Gazelle'
  },
  cssBaItemHeader: {
    characters: 'BAItemHeader',
    name: 'cssBaItemHeader',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(0, 11, 97, 1)'
    },
    pageName: 'Gazelle'
  }
}

import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { ONE_SECOND } from '../../../../pure/libs/Consts'
import { BorderRadixes } from '../enums/BorderRadixes'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { isJustCreated } from '../../../../pure/libs/VitecNextOrderItemHelper'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusItem } from './VitecNextOrderItemStatusItem'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

const MAX_NUMBER_OF_IMAGES = 75

export const VitecNextOrderItemStatusImageCaptions = (props: VitecNextProductInfoItemLoadingProps) => {
  const { now } = props
  const [index, setIndex] = useState(0)
  const { data: vitedNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitedNextOrder?.jaguarResponses?.IMAGE_CAPTIONS
  const requestId = leopardOrder?.requestId
  const { data: upload } = useUpload(requestId)
  const { data: files = [] } = useUploadedFiles(requestId)
  const hasFailedUploadedFiles = files.length > 0 && files?.every((file) => !!file.error)

  const fileUrls = Object.values(vitedNextOrder?.publicImageUrls || {})

  const hasFiles = fileUrls.length > 0

  useEffect(() => {
    setTimeout(() => setIndex(index + 1), ONE_SECOND * 2)
  }, [index])

  const imgUrl = fileUrls[index % fileUrls.length]

  return (
    <Box fullWidth>
      {/* TODO WRITE TEST, should not show images for done, failed status */}
      {hasFiles &&
        requestId &&
        upload?.status === 'processing' &&
        !hasFailedUploadedFiles &&
        isJustCreated(now, leopardOrder) && (
          <Box fullWidth align="center">
            <ImageContainer src={imgUrl} style={{ maxWidth: 200, maxHeight: 100 }} />
          </Box>
        )}
      <Box top={hasFiles} fullWidth>
        <VitecNextOrderItemImageCaptionsStatusView {...props} />
      </Box>
    </Box>
  )
}

export const ImageContainer = styled.img`
  border-radius: ${BorderRadixes.regular};
  max-width: 200px;
  max-height: 100px;
`

export const VitecNextOrderItemImageCaptionsStatusView = (props: VitecNextProductInfoItemLoadingProps) => {
  const { isProductEnabled } = props
  const noOfDocuments = isProductEnabled?.noOfDocuments
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.IMAGE_CAPTIONS
  const requestId = leopardOrder?.requestId
  const enablePreview = leopardOrder?.enablePreview
  const { data: upload } = useUpload(requestId)
  const { data: files = [] } = useUploadedFiles(requestId)

  if (!vitecNextOrder) return null

  if (noOfDocuments === 0)
    return <VitecNextOrderItemStatusView textKey={Texts.ImageTextsNotificationItNoImgages} type="warning" />

  if (upload?.status !== 'done' && noOfDocuments && noOfDocuments > MAX_NUMBER_OF_IMAGES)
    return (
      <VitecNextOrderItemStatusView
        textKey={Texts.ImageTextsErrorImageTextsToMany}
        text={formatText(getFigmaText(Texts.ImageTextsErrorImageTextsToMany), [noOfDocuments])}
        type="warning"
      />
    )

  const successText = !enablePreview
    ? formatText(getFigmaText(Texts.ImageTextsNotificationItCompleted), [noOfDocuments])
    : undefined

  return (
    <VitecNextOrderItemStatusItem
      {...props}
      vitecNextOrder={vitecNextOrder}
      vitecNextProduct={VitecNextProduct.IMAGE_CAPTIONS}
      upload={upload}
      uploadedFiles={files}
      isProductEnabled={isProductEnabled}
      successText={successText}
    />
  )
}

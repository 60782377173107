import React, { ReactElement } from 'react'
import { TextKey } from '../../../../pure/types/Antiloop'
import { getFigmaText } from '../libs/TextRepository'

export type FigmaTextProps = {
  textKey?: TextKey
  styleTextKey?: TextKey
  className?: string
  text?: string
  onClick?: () => unknown
  anchorHref?: string
  textTransform?: 'uppercase'
  separator?: ReactElement
  separatorWhenEmptyContent?: ReactElement
  lineHeight?: number
}

const FigmaText: React.FC<FigmaTextProps> = (props) => {
  const { text: _text, styleTextKey, textKey, className, anchorHref, textTransform } = props
  let { style } = styleTextKey || textKey || {}

  if (!style) return null

  if (style.textDecoration === 'UNDERLINE') style = { ...style, textDecoration: 'underline' }
  else style = { ...style, textDecoration: 'inherit' }

  let text = typeof _text === 'string' ? _text : (textKey && getFigmaText(textKey)) || ''
  if (textTransform === 'uppercase') text = text.toUpperCase()
  if (anchorHref)
    return (
      <a
        href={anchorHref}
        style={{ ...style, textDecoration: 'underline' }}
        dangerouslySetInnerHTML={{ __html: text }}
        target="_blank"
        rel="noreferrer"
      />
    )

  return <span dangerouslySetInnerHTML={{ __html: text }} className={className} style={style} />
}

export default FigmaText

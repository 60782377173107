import dayjs from 'dayjs'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { createUniqueId } from '../../../../pure/libs/Common'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { VitecNextProductInfoItems } from '../components/VitecNextProductInfoItems'
import { State } from '../hooks/useAppState'
import { createJaguarOrderForVitecNextOrder } from '../libs/CloudFunctionsApiHandler'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { getFeatureFlagsFromUrl } from './Common'
import { updateVitecNOOrder } from './DBApiHandler'

export type CreateLeopardOrderArgs = {
  vitecNextOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  requestId?: string
  state: State
  enablePreview: boolean
  extra?: LeopardOrder['extra']
}

export const createLeopardOrder = async ({
  vitecNextOrder,
  vitecNextProduct,
  requestId = createUniqueId(),
  enablePreview,
  state,
  extra
}: CreateLeopardOrderArgs) => {
  const opts = VitecNextProductInfoItems[vitecNextProduct].opts || {}
  opts.featureFlags = getFeatureFlagsFromUrl()

  const leopardOrder: LeopardOrder = {
    _id: requestId,
    requestId,
    createdAt: dayjs().format(),
    enablePreview,
    finishedAt: null,
    product: vitecNextProduct,
    extra,
    opts
  }

  vitecNextOrder = await updateVitecNOOrder({
    ...vitecNextOrder,
    jaguarResponses: {
      ...vitecNextOrder.jaguarResponses,
      [vitecNextProduct]: leopardOrder
    }
  })

  await upsertLeopardOrder({ leopardOrder, vitecNextOrder })

  await createJaguarOrderForVitecNextOrder(
    {
      requestId,
      vitecNextOrderId: vitecNextOrder.orderId,
      vitecNextProduct,
      extra,
      opts
    },
    state
  )

  return vitecNextOrder
}

/* eslint-disable prettier/prettier */
import { getPathToUploadedFiles } from '../../../../pure/libs/UploadPathHelper.js'
import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes.js'
import { Ad, CreateUserRequest, ExternalSession, Id, JaguarSession, JaguarSessionRequest, Organization, User } from '../../../../pure/types/types.js'
import Collections, { CollectionGroups } from '../../../../pure/libs/Collections.js'
import * as Repository from './FirebaseStorageClientForWebApp.js'
import { State } from '../hooks/useAppState.js'
import { db } from './Firebase.js'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder.js'
import { getObject, removeObject } from './FirebaseStorageClientBase.js'
import { doc, setDoc } from 'firebase/firestore'
import { CollectionGroup } from 'firebase-admin/firestore'

export const getUser = (id: Id): Promise<User | undefined> => Repository.getObject(id, Collections.USERS) as Promise<User | undefined>
export const updateUser = (user: User): Promise<User> => Repository.updateObject(user, Collections.USERS)
export const createUser = (user: CreateUserRequest, state: State): Promise<User> => Repository.createObject(user, Collections.USERS, state) as Promise<User>

export const createJaguarSession = (s: JaguarSessionRequest, state: State): Promise<JaguarSession> => Repository.createObject(s, Collections.JAGUAR_SESSIONS, state) as Promise<JaguarSession>
export const updateJaguarSession = (s: JaguarSession): Promise<JaguarSession | undefined> => Repository.updateObject(s, Collections.JAGUAR_SESSIONS)
export const removeJaguarSession = (s: JaguarSession): Promise<unknown> => Repository.removeObject(s, Collections.JAGUAR_SESSIONS)
export const getJaguarSession = (id: Id): Promise<JaguarSession | undefined> => Repository.getObject(id, Collections.JAGUAR_SESSIONS) as Promise<JaguarSession | undefined>
export const getJaguarSessions = (uid: string): Promise<JaguarSession[]> => Repository.getAllObjectsWithUserIdQuery(uid, Collections.JAGUAR_SESSIONS) as unknown as Promise<JaguarSession[]>

export const updateOrganization = (org: Organization): Promise<Organization> => Repository.updateObject(org, Collections.ORGANIZATIONS)

export const getExternalSession = (id: Id): Promise<ExternalSession | undefined> => Repository.getObject(id, Collections.EXTERNAL_SESSIONS) as Promise<ExternalSession | undefined>
export const updateExternalSession = (org: ExternalSession): Promise<ExternalSession> => Repository.updateObject(org, Collections.EXTERNAL_SESSIONS)

export const getAd = (id: Id): Promise<Ad | undefined> => Repository.getObject(id, Collections.ADS) as Promise<Ad | undefined>
export const updateAd = (ad: Ad): Promise<Ad> => Repository.updateObject(ad, Collections.ADS)
export const removeAd = (ad: Ad) => Repository.removeObject(ad, Collections.ADS)

export const getUpload = (uploadId: string): Promise<Upload | undefined> => getObject(uploadId, Collections.UPLOADS, db) as Promise<Upload | undefined>
export const removeUpload = (uploadId: string) => removeObject(uploadId, Collections.UPLOADS, db)
export const updateUpload = (upload: Upload): Promise<Upload> => Repository.updateObject({...upload, _id: upload.id} as any, Collections.UPLOADS)

export const updateUploadedFile = (file: UploadedFile, upload: Upload) => Repository.updateObject({...file, _id: file.id} as any, getPathToUploadedFiles(upload.id))

export const getVitecNOOrder = (id: string): Promise<VitecNextOrder | undefined> => Repository.getObject(id, Collections.VITEC_NO_ORDER) as Promise<VitecNextOrder | undefined>

export const updateVitecNOOrder = (o: VitecNextOrder): Promise<VitecNextOrder> => Repository.updateObject(o, Collections.VITEC_NO_ORDER)

export const upsertLeopardOrder = ({ leopardOrder, vitecNextOrder }: { leopardOrder: LeopardOrder; vitecNextOrder: VitecNextOrder }) => 
        setDoc(doc(db, Collections.VITEC_NO_ORDER, vitecNextOrder._id, CollectionGroups.LEOPARD_ORDERS, leopardOrder._id), leopardOrder)
import { useParams } from 'react-router-dom'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { Layout } from '../components/Layout'
import { VitecNextOrderProductDiff } from '../components/VitecNextOrderProductDiff'

export const VitecNextOrderProductDiffPage = () => {
  const { vitecNextOrderId, vitecNextProduct } = useParams<VitecNextOrderProductProps>()

  if (!vitecNextOrderId) return <Layout enableMenu />
  if (!vitecNextProduct) return <Layout enableMenu />

  return <VitecNextOrderProductDiff vitecNextOrderId={vitecNextOrderId} vitecNextProduct={vitecNextProduct} />
}

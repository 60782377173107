import React from 'react'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { fulfillLeopardOrder } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Button from './Button'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'
import Box from './Box'
import Tooltip from './Tooltip'

export const VitecNextOrderProductButtonsFulfill = (
  props: {
    disabled?: boolean
    fulfillMentSuccessText?: string
    onPreFulfillment?: () => unknown
  } & VitecNextOrderProductProps
) => {
  const {
    vitecNextOrderId,
    vitecNextProduct,
    fulfillMentSuccessText = getFigmaText(Texts.standardNotificationUploadComplete)
  } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const { state } = useAppState()
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const [isSuccess, setIsSuccess] = React.useState(false)

  const onClickFulfillLeopardOrder = () =>
    Promise.resolve(setIsLoading(true))
      .then(() => props.onPreFulfillment?.())
      .then(
        () =>
          leopardOrder &&
          fulfillLeopardOrder({ vitecNextOrderId: vitecNextOrder.orderId, requestId: leopardOrder?.requestId }, state)
      )
      .then(() => setIsSuccess(true))
      .catch((err) => captureAndNotifyError(err))
      .finally(() => setIsLoading(false))

  if (isSuccess)
    return (
      <Box>
        <VitecNextOrderItemStatusView
          type="success"
          textKey={Texts.ImageTextsNotificationItCompleted}
          text={fulfillMentSuccessText}
        />
      </Box>
    )

  return (
    <Box>
      <Tooltip
        title={props.disabled ? 'Opplasting til VitecNext for dette produktet er ikke tilgjengelig akkurat nå' : ''}
      >
        <Button
          buttonType="secondary"
          textKey={Texts.standardCtaUploadToNext}
          spacing={smallSpacing}
          onClick={onClickFulfillLeopardOrder}
          loading={isLoading}
          imageKey={Images.uploadArrowWhite}
        />
      </Tooltip>
    </Box>
  )
}

export enum Collections {
  USERS = 'users',
  LOGIN_WITH_CODE_TOKEN = 'login_with_code_token',
  CUSTOM_AUTH_TOKENS = 'custom_auth_tokens',
  MAIL = 'mail',
  JAGUAR_SESSIONS = 'jaguar_sessions',
  PRODUCTS = 'products',
  ORGANIZATIONS = 'organizations',
  EXTERNAL_SESSIONS = 'external_sessions',
  ADS = 'ads',
  UPLOADS = 'uploads',
  VITEC_ORDER = 'vitec_order',
  VITEC_NO_ORDER = 'vitec_no_order',
  VITEC_NEXT_ESTATES = 'vitec_next_estates',
  FEATURE_FLAGS = 'feature_flags'
}

export enum CollectionGroups {
  LEOPARD_ORDERS = 'leopard_orders'
}

export default Collections

import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { BasicAssociationFields } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { useVitecNOOrder } from './QueryHooks'
import { updateVitecNOOrder } from '../libs/DBApiHandler'

export const useBasicAssociaionFieldsState = (vitecNextOrderId: string) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const leopardOrder = vitecNextOrder?.jaguarResponses?.[VitecNextProduct.BASIC_ASSOCIATION]
  const basicAssociationFields = leopardOrder?.extra?.basicAssociationFields

  const updateBasicAssociationFields = (fields: BasicAssociationFields) =>
    vitecNextOrder &&
    leopardOrder &&
    updateVitecNOOrder({
      ...vitecNextOrder,
      jaguarResponses: {
        ...vitecNextOrder.jaguarResponses,
        [VitecNextProduct.BASIC_ASSOCIATION]: {
          ...leopardOrder,
          extra: { basicAssociationFields: fields }
        }
      }
    })

  return { basicAssociationFields, updateBasicAssociationFields }
}

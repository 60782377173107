import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath.js'
import { smallSpacing } from '../enums/Spacings.js'
import useAppState from '../hooks/useAppState.js'
import { OnClickVitecNextProductInfoItem, VitecNextProductInfoItemButton } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'

import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse.js'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct.js'
import { createUniqueId } from '../../../../pure/libs/Common.js'
import { isJustCreated, isProcessingUpload } from '../../../../pure/libs/VitecNextOrderItemHelper.js'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks.js'
import useAppStateSession from '../hooks/useAppStateSession.js'
import { getUpload } from '../libs/DBApiHandler.js'
import { getFigmaText } from '../libs/TextRepository.js'
import Button from './Button.js'
import { captureException } from '../libs/ErrorHelper.js'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems.js'

type Props = {
  vitecNOOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  button: VitecNextProductInfoItemButton
  isProductEnabled?: IsVitecNextProductEnabledResponse
  index?: number
  now: string
  isLoading?: boolean
}

export const VitecNextOrderItemButton = (props: Props): JSX.Element => {
  const navigate = useNavigate()
  const { state: sessionState } = useAppStateSession()
  const { enablePreview = false } = sessionState
  const { button, isProductEnabled, index = 0, vitecNextProduct, now } = props
  const { enableFulfillment = true } = VitecNextProductInfoItems[vitecNextProduct]
  const { data: vitecNextOrder = props.vitecNOOrder } = useVitecNOOrder(props.vitecNOOrder._id)
  const vitecNextOrderId = vitecNextOrder._id
  const [isLoading, setIsLoading] = useState<boolean>()

  const leoparOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]

  const { data: upload } = useUpload(leoparOrder?.requestId)

  const { state } = useAppState()

  const onClick = async (onClick: OnClickVitecNextProductInfoItem) => {
    const requestId = createUniqueId()
    const uploadId = requestId
    setIsLoading(true)

    Promise.resolve()
      .then(async () => {
        const routePath = formatRoutePathVitecNextOrderProduct({ vitecNextOrderId, vitecNextProduct })

        return Promise.resolve()
          .then(() => onClick({ vitecNextOrder, vitecNextProduct, requestId, state, enablePreview }))
          .then(async () => {
            if (!enableFulfillment) return navigate(routePath)

            const upload = await getUpload(uploadId)

            // TODO WRITE TEST: Should navigate to preview page
            if (upload?.status === 'done') navigate(routePath)
          })
          .catch(async (e) => {
            const upload = await getUpload(uploadId)
            // TODO WRITE TEST: Should navigate to preview page in case of error and upload is done
            if (upload?.status === 'done') navigate(routePath)
            throw e
          })
      })
      .catch(captureException)
      .finally(() => setIsLoading(false))
  }

  let buttonText = button.getButtonText?.(isProductEnabled) || getFigmaText(button.buttonTextKey)
  buttonText = buttonText?.replace('Punktmarkeri', 'Punktmakert')

  return (
    <Box left={index > 0} spacing={smallSpacing}>
      <Button
        textKey={button.buttonTextKey}
        onClick={() => onClick(button.onClick)}
        text={buttonText}
        disabled={
          isLoading || !isProductEnabled?.isEnabled || (isJustCreated(now, leoparOrder) && isProcessingUpload(upload))
        }
        loading={props.isLoading || (button.enableLoading && isLoading)}
      />
    </Box>
  )
}

import GazelleFigmaTexts from '../../../../pure/assets/i18n/figma.json' assert { type: 'json' }
import { FIGMA_MODES_LANG } from '../../../../pure/libs/Consts'
import OBPFigmaTexts from '../../../../pure/obp/i18n/figma.json' assert { type: 'json' }
import { Language, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop.js'
import { getLanguageCode } from '../hooks/useDefaultLanguage'
import { getVariable, Variable, VariableKey } from './Variables'

export type Printable = string | number | undefined

export type Options = {
  boldParams?: boolean
  boldParamsIndex?: number
  splitCharacter?: RegExp
}

const FigmaTexts = { ...OBPFigmaTexts.figma, ...GazelleFigmaTexts }

export const getFigmaText = (textKey: TextKeyWithOnlyText, language: Language = getLanguageCode()): string => {
  switch (language) {
    case 'no':
      return textKey.texts?.no || textKey.characters || ''
    default:
      return FigmaTexts[textKey.name] || textKey.characters || ''
  }
}

export const getFigmaTextFromVariable = (key: VariableKey, language: Language = getLanguageCode()): string =>
  getVariable(key).values.find(({ mode }) => mode === FIGMA_MODES_LANG[language])?.value || ''

export const formatText = (
  text: string,
  params: Printable[],
  { boldParams, boldParamsIndex, splitCharacter = /xxx|yyy|XXX/ }: Options = {}
): string => {
  const _params = params
    .map((p) => p?.toString() || '')
    .map((p, i) => {
      if (boldParams) return makeBold(p)
      if (boldParamsIndex === i) return makeBold(p)
      return p
    })

  return text.split(splitCharacter).reduce(
    (a, b, i) => {
      if (b.includes('@link')) b = b.replace('@link', '') // remove @link, it needs to be handled in the component
      return { res: a.res + b + (_params[a.i] || '').toString(), i: i + 1 }
    },
    { res: '', i: 0 }
  ).res
}

export function makeBold(p: string): string {
  return `<b>${p}</b>`
}

import { defaultStyles, FileIcon as ReactFileIcon } from 'react-file-icon'
import Colors from '../../../../pure/libs/Colors'
import Box from './Box'

export const SandboxPage = () => {
  const color = Colors.secondary100
  return <FileIcon url={'jkdfkj.pdf'} />
}

export const FileIcon = ({
  url,
  color = Colors.secondary100,
  size = 100
}: {
  url: string
  color?: string
  size?: number
}) => {
  const extension = url.split('.').pop()
  return (
    <Box width={`${size}px`}>
      <ReactFileIcon
        extension={extension || 'pdf'}
        {...defaultStyles.docx}
        labelColor={color}
        color={color}
        foldColor={color}
      />
    </Box>
  )
}

export default SandboxPage

/**
 * Format: int32
 * @description Importtypene som ønskes lagret
 *
1 = ChangeOfOwnershipFee (Eierskiftegebyr)

2 = CollectiveDebt (Andel fellesgjeld)

3 = CommunityTax (Kommunale avgifter)

4 = Deposit (Innskudd)

5 = EstateCorporationPartialDenominatedBond (Partialobligasjon pålydende)

6 = EstimatedValue (Verditakst)

7 = LoanFare (Lånetakst)

8 = PreemptiveFee (Gebyr forkjøpsrett)

9 = RentPrMonth (Felleskostnader per måned)

10 = ShareJointCapital (Andel fellesformue)

11 = PlotSize (Tomteareal)
 * @enum {integer}
 */

export enum VitecNextImportDoubleType {
  ChangeOfOwnershipFee = 1,
  CollectiveDebt = 2,
  CommunityTax = 3,
  Deposit = 4,
  EstateCorporationPartialDenominatedBond = 5,
  EstimatedValue = 6,
  LoanFare = 7,
  PreemptiveFee = 8,
  RentPrMonth = 9,
  ShareJointCapital = 10,
  PlotSize = 11
}

import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { TextKey } from '../../../../pure/types/Antiloop'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { smallSpacing } from '../enums/Spacings'
import { SectionInfoItem } from '../libs/SectionInfo'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import * as AppImagesRepository from '../libs/ImagesRepositoryApp'

export default function SignInLandingCard({ sectionInfo }: { sectionInfo: SectionInfoItem }) {
  const width = `${sectionInfo.landingPageCardImageKey.width}px`
  return (
    <Container>
      <Box width={width}>
        <FigmaImageContainer
          imageKey={sectionInfo.landingPageCardImageKey}
          imageStyle={{ borderRadius: BorderRadixes.moderate }}
          Images={AppImagesRepository}
        />
        <Box fullPadding>
          <Box multiline>
            <FigmaTextWithStyle type="h6" textKey={sectionInfo.landingPageHeaderTextKey as TextKey} />
          </Box>
          <Box top multiline spacing={smallSpacing}>
            <FigmaTextWithStyle type="body1" textKey={sectionInfo.typeOfAdDescriptionTextKey as TextKey} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background: var(--Neutral10, ${Colors.neutral10});
  box-shadow: ${Shadows.big};
`

import { AuthErrorCodes } from 'firebase/auth'
import { VitecNextOrderProductProps } from '../types/VitecNextOrderProductProps'
import { addQueryParamsToUrl } from './QueryParamHelper'

enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  MY_AI = '/my-ai',
  MY_ORG = '/my-agency',
  CREATE_AD = '/create-ad',
  UPLOAD = '/summarize',
  INFO_MAP = '/info-map',
  PRICING = '/pricing',
  PRICING_WEB_IFRAME = '/pricing-web-iframe',
  PRODUCTS = '/products',
  SIGN_UP = '/signup',
  LANDING_FASAD = '/landing-fasad',
  LINKED_IN = '/linked-in',
  TERMS_AND_CPNDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  RESEND_SECURE_LINK = '/resend-secure-link',
  SETTINGS = '/settings',
  EXTERNAL_SESSION = '/external-session',
  VITEC_LANDING = '/vitec-landing',
  LOGIN_WITH_CUSTOMER = '/login-with-customer',
  VITEC_NEXT_ORDER = '/vitec-next-order',
  VITEC_NEXT_ORDER_ALL = '/all-vitec-next-order',
  VITEC_NEXT_ORDER_PRODUCT = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct',
  VITEC_NEXT_ORDER_PRODUCT_FEEDBACK = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/feedback',
  VITEC_NEXT_ORDER_PRODUCT_DIFF = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/diff',
  VITEC_NEXT_FAQ = '/vitec-next-faq'
}

export const formatRoutePathVitecNextOrderProduct = (
  p: VitecNextOrderProductProps,
  routePath: RoutePath = RoutePath.VITEC_NEXT_ORDER_PRODUCT
) => formatRoutePath(routePath, p)

export function formatRoutePathVitexNextOrder(vitecNextOrderId: string) {
  return addQueryParamsToUrl(RoutePath.VITEC_NEXT_ORDER, { orderId: vitecNextOrderId })
}

const formatRoutePath = (r: RoutePath, o: object) =>
  Object.entries(o).reduce((acc, [k, v]) => acc.replace(`:${k}`, v), r as string)

export default RoutePath

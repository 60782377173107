import {
  BasicAssociationField,
  BasicAssociationFields,
  BasicAssociationResponseItemsKey,
  VitecNextOrderProductBasicAssociationItemsPure
} from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { getStringWithOnlyNumbers } from '../../../../pure/libs/Common'
import { NO, YES } from '../../../../pure/libs/Consts'
import { BasicAssociationResponseItems } from '../../../../pure/leopard/JaguarTypesDerived'
import { VitecNextOrderProductBasicAssociationItems } from './VitecNextOrderProductBasicAssociationItems'

export function getBasicAssociationFieldsFromJaguarResult(
  fields: BasicAssociationResponseItems
): BasicAssociationFields {
  return Object.entries(fields).reduce(
    (a, [key, v]) => {
      const item = VitecNextOrderProductBasicAssociationItemsPure[key as BasicAssociationResponseItemsKey]
      if (typeof v === 'number') v = getStringWithOnlyNumbers(v?.toString() || '')

      let value: string = v?.trim() || ''

      value = item.format?.(value) || value

      return {
        ...a,
        [key]: { key, value, isFulfilling: false, checked: true, fulfilledAt: null } as BasicAssociationField
      }
    },
    {
      has_board_approval: {
        key: 'has_board_approval',
        value: getYesOrNoStringValue(fields.styregodkjennelse),
        isFulfilling: false,
        checked: true,
        fulfilledAt: null
      },
      has_preemptive: {
        key: 'has_preemptive',
        value: getYesOrNoStringValue(fields.forkjøpsrett),
        isFulfilling: false,
        checked: true,
        fulfilledAt: null
      }
    } as BasicAssociationFields
  )
}

function getYesOrNoStringValue(norwegianString?): string {
  if (typeof norwegianString !== 'string') return NO
  return norwegianString.toLocaleLowerCase() === 'ja' ? YES : NO
}
export const shouldShowBasicAssociationField = ({ itemKey, basicAssociationFields }) => {
  // TODO WRITE TEST, should not show HasBoardApproval and HasPreEmptive (bool values)
  if (!VitecNextOrderProductBasicAssociationItems[itemKey]?.textKey) return false
  if (!basicAssociationFields[itemKey]) return false

  return true
}

import VariablesJSON from '../assets/design_tokens/gazelle_design-tokens.tokens.json' assert { type: 'json' }

export type Variable = {
  name: string
  values: {
    mode: string
    value: string
  }[]
  variableId: string
}

export type VariableKey = keyof typeof Variables

const Variables = VariablesJSON.variables

export const getVariable = (key: VariableKey): Variable => Variables[key] as Variable

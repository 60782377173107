export enum VitecNextProduct {
  IMAGE_CAPTIONS = 'IMAGE_CAPTIONS',
  CONSTRUCTION_INFO = 'CONSTRUCTION_INFO',
  SHORT_LONG_DESCRIPTION = 'SHORT_LONG_DESCRIPTION',
  VALIDATE_AD = 'VALIDATE_AD',
  BASIC_ASSOCIATION = 'BASIC_ASSOCIATION',
  SELF_DECLERATION = 'SELF_DECLERATION',
  DESCRIPTIONS = 'DESCRIPTIONS'
}

export const LiveProducts: { [key in VitecNextProduct]: boolean } = {
  IMAGE_CAPTIONS: true,
  CONSTRUCTION_INFO: true,
  SHORT_LONG_DESCRIPTION: true,
  BASIC_ASSOCIATION: true,
  VALIDATE_AD: false,
  SELF_DECLERATION: true,
  DESCRIPTIONS: false
}

import { useEffect } from 'react'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'

export const OnMountApp = () => {
  useOnMountApp()
  return null
}

export const useOnMountApp = () => {
  useEffect(() => {
    eventEmitter.on(Events.NEW_ANALYTICS_EVENT, (data) => console.log('New GA Event: ', data))

    return () => {
      eventEmitter.removeAllListeners()
    }
  }, [])
}

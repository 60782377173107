import { OAuthProvider } from 'firebase/auth'
import { createAuthenticationTokenForLoginToken, createLoginWithCodeToken } from './CloudFunctionsApiHandler'
import { fetchSignInMethodsForEmail, signInWithCustomToken, signInWithPopup } from './FirebaseHelper'

export type ServicesType = {
  createAuthenticationTokenForLoginToken: typeof createAuthenticationTokenForLoginToken
  createLoginWithCodeToken: typeof createLoginWithCodeToken
  fetchSignInMethodsForEmail: typeof fetchSignInMethodsForEmail
  signInWithCustomToken: typeof signInWithCustomToken
  signInWithPopup: typeof signInWithPopup
  credentialFromError: typeof OAuthProvider.credentialFromError
}

export const SignInMachineServices = {
  createAuthenticationTokenForLoginToken,
  createLoginWithCodeToken,
  fetchSignInMethodsForEmail,
  signInWithCustomToken,
  signInWithPopup,
  credentialFromError: (err) => OAuthProvider.credentialFromError(err)
}

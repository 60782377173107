import { POST } from '../../../pure/libs/Consts.js'
import { SlackChannels } from '../../../pure/types/SlackChannels.js'
import config from './Config.js'

export enum SlackWebhooks {
  DEBUG = 'https://hooks.slack.com/services/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',
  ERRORS = 'https://hooks.slack.com/services/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'
}

// @flow
const method = 'POST'

export const slack = async (text: string, url: SlackWebhooks): Promise<unknown> => {
  if (!config?.enableSlack) return Promise.resolve(console.log(text))
  await fetch(url, { method, body: JSON.stringify({ text }) }).catch(() => ({}))
}

import { Outlet } from 'react-router-dom'
import config from '../Config'
import useClientWindowApi from '../hooks/useClientWindowApi'
import Box from './Box'
import SandboxLoggedInPage from './SandboxLoggedInPage'
import useOnLoggedIn from '../hooks/useOnLoggedIn'

const AppContainer = () => {
  useClientWindowApi()
  useOnLoggedIn()

  if (config.enableLoggedInSandbox) return <SandboxLoggedInPage />

  return (
    <Box fullWidth fullHeight>
      <Outlet />
    </Box>
  )
}

export default AppContainer

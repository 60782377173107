import { useUpload } from './QueryHooks'
import useAppState from './useAppState'
import { useAsyncFunction } from './useAsyncFunction'
import { getJaguarResults } from '../libs/CloudFunctionsApiHandler'

export function useJaguarResults<T>(vitecNextOrderId: string, requestId: string | undefined) {
  const { state } = useAppState()
  const { data: upload } = useUpload(requestId || '')

  return useAsyncFunction<T>(() => getJaguarResults({ requestId: requestId || '', vitecNextOrderId }, state), {
    enabled: !!requestId && upload?.status === 'done'
  })
}

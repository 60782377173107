import * as ReactRouterDom from 'react-router-dom'
import { addQueryParamsToUrl, parseQueryParam } from './useQueryparamHelper'

export const useNavigate = (opts: { enablePreserveQueryParams?: boolean } = {}) => {
  const { enablePreserveQueryParams = true } = opts
  const navigate = ReactRouterDom.useNavigate()

  return <T>(path: string, queryParams?: T) => {
    const search = enablePreserveQueryParams ? window.location.search.replace('?', '') : ''
    const searchQueryParams = parseQueryParam<T>(search)

    return navigate(addQueryParamsToUrl(path, { ...searchQueryParams, ...queryParams }))
  }
}

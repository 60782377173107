import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { formatText, getFigmaText } from '../libs/TextRepository.js'
import { Texts } from '../libs/Texts.js'
import FigmaText from './FigmaText.js'

export function VitecNextOrderItemLongWaitFigmaText({ vitecNextOrder }: { vitecNextOrder: VitecNextOrder }) {
  return (
    <FigmaText
      textKey={Texts.standardNotificationLongJobEmail}
      text={formatText(getFigmaText(Texts.standardNotificationLongJobEmail), [vitecNextOrder.useremail])}
    />
  )
}

/**
 * 
 * 1 = HasBoardApproval (Har styregodkjennelse)

2 = HasPreemptiveRight (Har forkjøpsrett)
 */

export enum VitecNextImportBooleanType {
  HasBoardApproval = 1,
  HasPreemptiveRight = 2
}

import styled from '@emotion/styled'
import { TextareaAutosize as MaterialTextAreaAutoSize } from '@mui/base'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import React from 'react'
import { VitecNextProductPureInfoItems } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors, { LightGrey } from '../../../../pure/libs/Colors'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import Box from './Box'
import DiffLines from './DiffLines'
import { LoadingPage } from './LoadingPage'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

export const VitecNextOrderProductDiff: React.FC<VitecNextOrderProductProps> = (props) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const item = VitecNextProductInfoItems[vitecNextProduct]

  const {
    data: { upload, uploadedFiles },
    isLoading: isLoadingNew
  } = useUploadAndFiles({
    ...props,
    vitecNextProduct: VitecNextProductPureInfoItems[vitecNextProduct]?.vitecNextProductExperimental || vitecNextProduct
  })

  const {
    data: { upload: uploadBase, uploadedFiles: uploadedFilesBase },
    isLoading: isLoadingBase
  } = useUploadAndFiles(props)

  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const isLoading = isLoadingNew || isLoadingBase

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  // const baseJson = item.getJSON?.({ upload: uploadBase, uploadedFiles: uploadedFilesBase }) || ''
  // const newJSON = item.getJSON?.({ upload, uploadedFiles }) || ''

  // if (!!baseJson && !!newJSON) return <DiffLines base={baseJson} newText={newJSON} fn={diffJson} />

  const base = item.getText?.({ upload: uploadBase, uploadedFiles: uploadedFilesBase }) || ''
  const newText = item.getText?.({ upload, uploadedFiles }) || ''

  return <DiffLines base={base} newText={newText} />
}

export const Textarea = (props: TextFieldProps) => {
  return <TextareaAutoSize autoFocus {...props} />
}

const TextareaAutoSize = styled(MaterialTextAreaAutoSize)({
  border: `1px solid ${LightGrey}`,
  outline: 'none',
  resize: 'none',
  borderRadius: BorderRadixes.regular,
  color: Colors.primary100
}) as any

const useUploadAndFiles = (props: VitecNextOrderProductProps) => {
  const { vitecNextProduct, vitecNextOrderId } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const { data: upload } = useUpload(leopardOrder?.requestId || '')
  const { data: uploadedFiles = [] } = useUploadedFiles(leopardOrder?.requestId || '')

  return { data: { upload, uploadedFiles }, isLoading }
}

import React from 'react'
import { ONE_SECOND } from '../../../../pure/libs/Consts'
import dayjs from 'dayjs'

export const useNow = () => {
  const [now, setNow] = React.useState<string>(dayjs().format())

  React.useEffect(() => {
    const i = setInterval(() => setNow(dayjs().format()), ONE_SECOND)
    return () => clearInterval(i)
  }, [])

  return now
}

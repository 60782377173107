import styled from '@emotion/styled'
import React from 'react'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { shouldShowLongWaitMessage } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors from '../../../../pure/libs/Colors'
import { createUniqueId } from '../../../../pure/libs/Common'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import useAppStateSession from '../hooks/useAppStateSession'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import Images from '../libs/Images'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { Texts } from '../libs/Texts'
import { onCopyText } from '../libs/onClickCopy'
import Box from './Box'
import Button from './Button'
import ProgressBar from './ProgressBar'
import { BetaButton } from './VitecNextOrderItemIconView'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'
import { VitecNextOrderProductButtonsFulfill } from './VitecNextOrderProductButtonsFulfill'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { useJaguarResults } from '../hooks/useJaguarResults'

export function VitecNextOrderProductButtons(props: {
  vitecNextOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  SuffixComponent?: React.FC
  fulfillMentSuccessText?: string
  enableCopy?: boolean
  enableRegenerate?: boolean
  onPreFulfill?: () => void
  onPostFulfill?: () => void
}) {
  const {
    vitecNextProduct,
    SuffixComponent = Box,
    fulfillMentSuccessText,
    enableCopy = true,
    enableRegenerate = true
  } = props
  const { enableFulfillment = true } = VitecNextProductInfoItems[vitecNextProduct]
  const vitecNextOrderId = props.vitecNextOrder._id
  const { data: vitecNextOrder = props.vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const {
    state: { enablePreview = true }
  } = useAppStateSession()

  const [requestId, setRequestId] = React.useState(leopardOrder?.requestId)
  const { data: upload } = useUpload(requestId)
  const { data: uploadedFiles } = useUploadedFiles(requestId)

  const { data: jaguarResults } = useJaguarResults(vitecNextOrderId, requestId)

  const context = useAppState()
  const { state } = context
  const [isRegenerating, setisRegenerating] = React.useState(false)
  const isLoading = isRegenerating
  const { getText, enableBetaUX } = VitecNextProductInfoItems[vitecNextProduct]

  if (!leopardOrder) return null

  const onClickCreateLeopardOrder = () => {
    const requestId = createUniqueId()
    setRequestId(requestId)
    Promise.resolve(setisRegenerating(true))
      .then(() =>
        createLeopardOrder({
          vitecNextOrder,
          vitecNextProduct,
          state,
          requestId,
          enablePreview: enablePreview || false
        })
      )
      .then(() => {
        setisRegenerating(false)
        window.location.reload() // Hi future dev, for some reason basicAssociation needs this in order to show the new data, i also fear that other products are showing stale data, good luck!
      })
  }

  if (isRegenerating)
    return (
      <Container fullWidth fullPadding>
        <ProgressBar />
        {shouldShowLongWaitMessage({ upload, vitecNextProduct }) && (
          <Box top spacing={smallSpacing}>
            <VitecNextOrderItemLongWaitFigmaText vitecNextOrder={vitecNextOrder} />
          </Box>
        )}
      </Container>
    )

  return (
    <Container fullWidth fullPadding position="relative">
      <Box fullWidth direction="row" justify="space-between" align="center">
        <Box fullWidth direction="row" align="center">
          <VitecNextOrderProductButtonsFulfill
            fulfillMentSuccessText={fulfillMentSuccessText}
            disabled={!enableFulfillment || isRegenerating}
            vitecNextOrderId={vitecNextOrder.orderId}
            vitecNextProduct={vitecNextProduct}
          />
          {enableRegenerate && (
            <Box left>
              <Button
                buttonType="secondary"
                textKey={Texts.standardCtaRegenerate}
                spacing={smallSpacing}
                onClick={onClickCreateLeopardOrder}
                disabled={isLoading}
                imageKey={Images.regenerateText}
                loading={
                  isRegenerating &&
                  upload?.status !==
                    'processing' /** There is another spinner shown in parent component when upload is processing**/
                }
              />
            </Box>
          )}

          {enableCopy && (
            <Box left>
              <Button
                buttonType="secondary"
                textKey={Texts.standardCtaCopyText}
                spacing={smallSpacing}
                onClick={() => onCopyText(getText?.({ upload, uploadedFiles, jaguarResults }))}
                imageKey={Images.copyWhite}
              />
            </Box>
          )}
        </Box>
        <SuffixComponent />
        {enableBetaUX && <BetaButton />}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

import CloudFunctionError from './CloudFunctionError.js'
import { captureException } from './ErrorHelper.js'

export default function onUnhandledPromiseRejection(error: any) {
  if (error.code === 'auth/cancelled-popup-request') return
  if (error.code === 'auth/popup-closed-by-user') return
  if (error.code === 'auth/provider-already-linked') return
  if (error.code === 'auth/network-request-failed') return
  if (error.code === 'auth/internal-error') return
  if (error.code === 'auth/popup-blocked') return

  if (error instanceof CloudFunctionError) {
    const { cloudFunctionError, fnName, state } = error
    captureException(new Error(`${fnName} failed: ${cloudFunctionError.message}, user: ${state.user?.email}`))
  } else captureException(error)
}

import dayjs from 'dayjs'
import { useState } from 'react'
import { SuccessColor } from '../../../../pure/libs/Colors'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import {
  BasicAssociationFields,
  BasicAssociationResponseItemsKey
} from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { fulfillLeopardOrder } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { VitecNextOrderProductBasicAssociationItems } from '../libs/VitecNextOrderProductBasicAssociationItems'
import { onCopyText } from '../libs/onClickCopy'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import Loader from './Loader'
import Tooltip from './Tooltip'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { VitecNextOrderProductBasicAssociationItemEmptyState } from './VitecNextOrderProductBasicAssociationItemEmptyState'

export function VitecNextOrderProductBasicAssociationItem({
  itemKey,
  basicAssociationFields,
  setBasicAssociationFields,
  vitecNextOrder,
  enableUpload = true,
  leopardOrder
}: {
  itemKey: BasicAssociationResponseItemsKey
  basicAssociationFields: BasicAssociationFields
  setBasicAssociationFields: (fields: BasicAssociationFields) => void
  enableUpload?: boolean
  vitecNextOrder: VitecNextOrder
  leopardOrder: LeopardOrder
}) {
  const { state } = useAppState()
  const { orderId: vitecNextOrderId } = vitecNextOrder
  const requestId = leopardOrder.requestId
  const [loading, setLoading] = useState(false)

  const item = VitecNextOrderProductBasicAssociationItems[itemKey]

  const { checked = true, fulfilledAt, isFulfilling, value } = basicAssociationFields[itemKey]

  if (!value) return <VitecNextOrderProductBasicAssociationItemEmptyState itemKey={itemKey} />

  const isLoading = loading || isFulfilling

  const onClickChecked = (checked: boolean, key: BasicAssociationResponseItemsKey, fields: BasicAssociationFields) =>
    setBasicAssociationFields({ ...fields, [key]: { ...fields[key], checked, isFulfilling: false } })

  const onClickUpload = () =>
    Promise.resolve(setLoading(true))
      .then(() =>
        fulfillLeopardOrder(
          {
            vitecNextOrderId,
            requestId,
            basicAssociationFulfillRequest: {
              key: itemKey
            }
          },
          state
        )
      )
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  return (
    <Box direction="row" fullWidth justify="space-between">
      <Box fullWidth direction="row">
        {enableUpload && (
          <Box
            onClick={() =>
              onClickChecked(!checked, itemKey as BasicAssociationResponseItemsKey, basicAssociationFields)
            }
            pointer
            top
            spacing={minSpacing}
          >
            <Checkbox value={checked} />
          </Box>
        )}
        <Box left spacing={smallSpacing}>
          <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={item.textKey} />
          <Box fullWidth>
            <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={value} />
          </Box>
        </Box>
      </Box>
      <Box direction="row">
        {false && (
          <Box left spacing={smallSpacing} pointer>
            <Tooltip title={getFigmaText(Texts.feedbackCtaSendFeedback)} placement="top">
              <FigmaImageContainer imageKey={Images.smallFeedbackIcon} />
            </Tooltip>
          </Box>
        )}
        {enableUpload && (
          <Box left spacing={smallSpacing} pointer>
            <Tooltip
              title={
                fulfilledAt
                  ? formatText(getFigmaText(Texts.standardCtaUploadedToNextAt), [
                      dayjs(fulfilledAt).format(YYYYMMDD_HHmm)
                    ])
                  : getFigmaText(Texts.standardCtaUploadToNext)
              }
              placement="top"
              backgroundColor={fulfilledAt ? SuccessColor : undefined}
              onClick={() => onClickUpload()}
            >
              <Box>
                {isLoading && <Loader size={Images.uploadArrow.width} />}
                {!isLoading && (
                  <FigmaImageContainer imageKey={fulfilledAt ? Images.uploadedArrow : Images.uploadArrow} />
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
        <Box left spacing={smallSpacing} pointer onClick={() => onCopyText(value)}>
          <Tooltip title={getFigmaText(Texts.standardCtaCopyText)} placement="top">
            <FigmaImageContainer imageKey={Images.copyBlack} />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

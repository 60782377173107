import styled from '@emotion/styled'
import { getArray } from '../../../../pure/libs/Common'
import { SectionInfoArray } from '../libs/SectionInfo'
import Box from './Box'
import SignInLandingCard from './SignInLandingCard'

const NUMBER_OF_COLUMNS = 5
const SectionInfoArrayDouble = getArray(NUMBER_OF_COLUMNS).reduce(
  (arr, index) => arr.concat(SectionInfoArray),
  [] as any
)

const DoubleSectionInfoArray = SectionInfoArray.concat(SectionInfoArray)

export function SignInLandingCards() {
  return (
    <Container fullWidth fullHeight direction="row">
      {getArray(NUMBER_OF_COLUMNS).map((_, index) => (
        <InnerContainer key={index} right left>
          {DoubleSectionInfoArray.map((sectionInfo, key) => (
            <Box key={key} fullPadding>
              <SignInLandingCard sectionInfo={SectionInfoArrayDouble[index + key]} />
            </Box>
          ))}
        </InnerContainer>
      ))}
    </Container>
  )
}

const Container = styled(Box)`
  transform: translate(-400px, -800px);
`
const InnerContainer = styled(Box)`
  transform: rotate(30deg);
`

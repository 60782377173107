import styled from '@emotion/styled'
import { diffLines, diffSentences, diffChars, diffWordsWithSpace, diffTrimmedLines, diffWords } from 'diff'
import React from 'react'
import Box from './Box'

export default function DiffLines({ base, newText }: { base: any; newText: any }) {
  const [index, setIndex] = React.useState(0)
  const fns = [diffTrimmedLines, diffChars, diffLines, diffSentences, diffWordsWithSpace, diffWords]
  const fn = fns[index % fns.length]
  const diffs = fn(base, newText)

  return (
    <Box fullWidth onClick={() => setIndex(index + 1)}>
      <div>
        {diffs.map((props, i) => {
          const { added, removed, value: text } = props
          const Component = added ? Ins : removed ? Del : styled.span``

          const texts = text.split('\n')

          return (
            <>
              {texts.map((text, j) => {
                return (
                  <React.Fragment key={`${i}${j}`}>
                    <Component dangerouslySetInnerHTML={{ __html: text }} />
                    {!text && <br />}
                  </React.Fragment>
                )
              })}
            </>
          )
        })}
      </div>
    </Box>
  )
}

const Ins = styled.span`
  display: inline;
  background: #eaf2c2;
  color: #406619;
  text-decoration: none;
`

const Del = styled.span`
  display: inline;
  background: #f9c0c0;
  color: #b30000;
  text-decoration: none;
`

import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { ONE_SECOND } from '../../../../pure/libs/Consts'
import { getThumbnailUrl } from '../../../../pure/libs/getThumbnailUrl'
import { isJustCreated } from '../../../../pure/libs/VitecNextOrderItemHelper'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { storage } from '../libs/Firebase'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { ImageContainer } from './VitecNextOrderItemStatusImageCaptions'

export const VitecNextOrderItemStatusFile = (props: VitecNextProductInfoItemLoadingProps) => {
  const { now, vitecNextProduct } = props
  const [imageUrl, setImageUrl] = useState('')
  const [index, setIndex] = useState(0)
  const { data: vitedNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitedNextOrder?.jaguarResponses?.[vitecNextProduct]
  const requestId = leopardOrder?.requestId
  const { data: upload } = useUpload(requestId)
  const { data: files = [] } = useUploadedFiles(requestId)
  const hasFailedUploadedFiles = files.length > 0 && files?.every((file) => !!file.error)

  const entries = Object.entries(leopardOrder?.documentUrls || {})

  const hasFiles = entries.length > 0

  useEffect(() => {
    setTimeout(() => setIndex(index + 1), ONE_SECOND * 2)
  }, [index])

  const entry = entries[index % entries.length]
  const url = entry?.[1]

  useEffect(() => {
    if (!url) return
    const thumbnailUrl = getThumbnailUrl(url)
    getDownloadURL(ref(storage, thumbnailUrl))
      .then((url) => setImageUrl(url))
      .catch(() => console.error('Failed to get thumbnail url'))
  }, [url])

  const onClick = () => getDownloadURL(ref(storage, url)).then((url) => window.open(url, '_blank'))

  if (!imageUrl) return null

  return (
    <Box fullWidth>
      {hasFiles &&
        requestId &&
        upload?.status === 'processing' &&
        !hasFailedUploadedFiles &&
        isJustCreated(now, leopardOrder) && (
          <Box fullWidth align="center" onClick={onClick} link>
            <ImageContainer src={imageUrl} style={{ maxWidth: 200, maxHeight: 100 }} />
          </Box>
        )}
    </Box>
  )
}

import { Bounce, toast } from 'react-toastify'
import { getFigmaText } from './TextRepository'
import { Texts } from './Texts'

export const success = (text: string) =>
  toast.success(text, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Bounce
  })

export const error = (text: string = getFigmaText(Texts.standardNotificationCiError)) =>
  toast.error(text, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Bounce
  })

import styled from '@emotion/styled'
import { ButtonProps as MaterialButtonProps } from '@mui/material/Button'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { ImageKey, TextKeyWithOnlyText } from '../../../../pure/types/Antiloop'
import Box from './Box'
import ButtonBase from './ButtonBase'
import FigmaTextLoader from './FigmaTextLoader'
import { defaultSpacing, smallSpacing } from '../enums/Spacings'
import { isPropValid } from '../libs/EmotionHelper'
import { Texts } from '../libs/Texts'
import FigmaImageContainer from './FigmaImageContainer'

export type ButtonProps = {
  className?: string
  spacing?: string
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'empty_state'
  textKey?: TextKeyWithOnlyText
  imageKey?: ImageKey
  loading?: boolean
  text?: string
  color?: string
  verticalSpacing?: string
} & MaterialButtonProps

export default function ButtonPrimary(props: ButtonProps) {
  const { children, className, textKey, loading, verticalSpacing, text, imageKey, ...buttonProps } = props
  return (
    <StyledButton
      className={className}
      variant="contained"
      verticalSpacing={verticalSpacing}
      color="primary"
      spacing={defaultSpacing}
      {...buttonProps}
    >
      <Box align="center" fullWidth>
        {children || (
          <Box>
            {imageKey && (
              <Box>
                <FigmaImageContainer imageKey={imageKey} />
              </Box>
            )}
            <Box left={!!imageKey} spacing={smallSpacing}>
              <FigmaTextLoader
                textKey={Texts.dashboardCtaGenerate}
                text={text || (textKey ? getFigmaText(textKey) : undefined)}
                loading={loading}
              />
            </Box>
          </Box>
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<{
  spacing?: string
  verticalSpacing?: string
}>``

/**
 * Format: int32
 * @description Importtypene som ønskes lagret
 *
1 = AccountingBudgeting (Regnskap/budsjett/kostnadsøkninger)

2 = AmountYearsInstallmentFree (Avdragsfrie år)

3 = Animals (Husdyrhold)

4 = BoardApproval (Styregodkjennelse)

5 = CommunityFee (Kommentar kommunale avgifter)

6 = ConstructionMethod (Byggemåte)

7 = GuaranteeFund (Sikringsordning fellesgjeld)

8 = InsuranceCompany (Borettslagets forsikringsselskap. MaxLength(255))

9 = InsurancePolicy (Polisenummer felles forsikring. MaxLength(255))

10 = JointCostAfterGracePeriod (Felleskostnader etter avdragsfri periode)

11 = JointDebtDescription (Kommentar fellesgjeld)

12 = JointDebtInstallment (Avdrag fellesgjeld)

13 = JointDebtInterest (Rentekostnader fellesgjeld)

14 = LoanTermsCollectiveDebt (Lånebetingelser fellesgjeld)

15 = PartAbout (Om boligselskapet (borettslaget, sameiet eller aksjeselskapet))

16 = Preemptive (Forkjøpsrett)

17 = RentIncludes (Felleskostnader inkluderer)

18 = ResidentCommitments (Beboernes forpliktelser og dugnader)

19 = Statutes (Vedtekter/husordensregler)

20 = ValuationType (Type rapport. MaxLength(255))

21 = PlotDescription (Tomtebeskrivelse)

22 = Standard (Standard)

23 = NonMeasurableAreas (Ikke målbare arealer)

24 = CommonAreasAndRightsToUse (Fellesarealer og rettigheter til bruk)

25 = ValuatorCommentsToArea (Bygningssakkyndiges kommentar til arealoppmåling)

26 = DeleteDocument (Referanse til dokument som skal slettes. Om det er et dokument med flere versjoner slettes alle versjoner)

27 = SolarConditions (Solforhold for eiendom (bruktbolig, boligutleie, prosjektenhet, landbrukseiendom eller kontraktsposisjon))
 * @enum {integer}
 */

export enum VitecNextImportTextType {
  AccountingBudgeting = 1,
  AmountYearsInstallmentFree = 2,
  Animals = 3,
  BoardApproval = 4,
  CommunityFee = 5,
  ConstructionMethod = 6,
  GuaranteeFund = 7,
  InsuranceCompany = 8,
  InsurancePolicy = 9,
  JointCostAfterGracePeriod = 10,
  JointDebtDescription = 11,
  JointDebtInstallment = 12,
  JointDebtInterest = 13,
  LoanTermsCollectiveDebt = 14,
  PartAbout = 15,
  Preemptive = 16,
  RentIncludes = 17,
  ResidentCommitments = 18,
  Statutes = 19,
  ValuationType = 20,
  PlotDescription = 21,
  Standard = 22,
  NonMeasurableAreas = 23,
  CommonAreasAndRightsToUse = 24,
  ValuatorCommentsToArea = 25,
  DeleteDocument = 26,
  SolarConditions = 27
}

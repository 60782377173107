import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { LightYellow, Yellow } from '../../../../pure/libs/Colors'
import RoutePath, { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function VitecNextOrderProductFeedbackButton(props: VitecNextOrderProductProps) {
  const navigate = useNavigate()
  return (
    <Box direction="row" align="center">
      <Container
        fullPadding
        direction="row"
        spacing={tinySpacing}
        align="center"
        pointer
        onClick={() =>
          navigate(formatRoutePathVitecNextOrderProduct(props, RoutePath.VITEC_NEXT_ORDER_PRODUCT_FEEDBACK))
        }
      >
        <Box>
          <FigmaImageContainer imageKey={Images.pngFeedbackIcon} />
        </Box>
        <Box left spacing={smallSpacing}>
          <FigmaText textKey={Texts.feedbackFeedbackTitle} />
        </Box>
      </Container>
      <Box left spacing={defaultSpacing}>
        <FigmaText textKey={HardCodedTexts.BasicAssociationBaFeedbackAdditionalText} />
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${LightYellow};
  border: 1px solid ${Yellow};
  border-radius: ${BorderRadixes.regular};
`

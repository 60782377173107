import Images from '../assets/images.json'

export const AppImages = {
  pngLoginPageInterior: {
    name: 'pngLoginPageInterior',
    id: '4885:98668',
    url: 'src/tiger/assets/images/pngLoginPageInterior.png',
    format: 'png',
    scale: 1,
    width: 393,
    height: 200,
    pageName: '🖥️ Gazelle App Production'
  },
  pngLoginPageGeneral: {
    name: 'pngLoginPageGeneral',
    id: '4885:98663',
    url: 'src/tiger/assets/images/pngLoginPageGeneral.png',
    format: 'png',
    scale: 1,
    width: 393,
    height: 200,
    pageName: '🖥️ Gazelle App Production'
  },
  pngLoginPageExterior: {
    name: 'pngLoginPageExterior',
    id: '4885:98673',
    url: 'src/tiger/assets/images/pngLoginPageExterior.png',
    format: 'png',
    scale: 1,
    width: 393,
    height: 200,
    pageName: '🖥️ Gazelle App Production'
  },
  pngLoginPageArea: {
    name: 'pngLoginPageArea',
    id: '4885:98699',
    url: 'src/tiger/assets/images/pngLoginPageArea.png',
    format: 'png',
    scale: 1,
    width: 393,
    height: 200,
    pageName: '🖥️ Gazelle App Production'
  }
}

export default Images

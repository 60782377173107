import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { DescriptionsResults, SelfDeclarationResults } from '../../../../pure/leopard/JaguarTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { FREMTIND_EX_PDF_URL } from '../../../../pure/libs/Consts'
import { getConstructionInfoTextFromUpload } from '../../../../pure/libs/getConstructionInfoTextFromUpload'
import Images from '../libs/Images'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaText from './FigmaText'
import { VitecNextOrderItemStatusConstructionInfo } from './VitecNextOrderItemStatusConstructionInfo'
import { VitecNextOrderItemStatusImageCaptions } from './VitecNextOrderItemStatusImageCaptions'
import { VitecNextOrderItemStatusLongAndShortDescription } from './VitecNextOrderItemStatusLongAndShortDescription'
import { VitecNextOrderProductBasicAssociation } from './VitecNextOrderProductBasicAssociation'
import { VitecNextOrderProductContructionInfo } from './VitecNextOrderProductContructionInfo'
import { VitecNextOrderProductDescriptions } from './VitecNextOrderProductDescriptions'
import { VitecNextOrderProductImageCaptions } from './VitecNextOrderProductImageCaptions'
import { VitecNextOrderProductLongShortDescription } from './VitecNextOrderProductLongShortDescription'
import { VitecNextOrderProductSelfDeclaration } from './VitecNextOrderProductSelfDeclaration'

export const VitecNextProductInfoItems: {
  [property in VitecNextProduct]: VitecNextProductInfoItem
} = {
  [VitecNextProduct.IMAGE_CAPTIONS]: {
    textKey: Texts.dashboardAutoCaptioningTitleCaptioningTitleg,
    descriptionKey: Texts.dashboardAutoCaptioningDescr,
    imageKey: Images.imageCaptionIcon,
    slug: 'bildetekst',
    PreviewComponent: VitecNextOrderProductImageCaptions,
    StatusComponent: VitecNextOrderItemStatusImageCaptions,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsCtaGenImageTexts,
        getButtonText: (r?: IsVitecNextProductEnabledResponse) =>
          formatText(getFigmaText(Texts.ImageTextsCtaGenImageTexts), [r?.noOfDocuments || '0']),
        onClick: (o) => createLeopardOrder(o)
      }
    ]
  },
  [VitecNextProduct.CONSTRUCTION_INFO]: {
    textKey: Texts.dashboardConstructionInfoTitle,
    descriptionKey: Texts.dashboardConstructionInfoDescr,
    imageKey: Images.constructionInfoIcon,
    slug: 'byggemate',
    PreviewComponent: VitecNextOrderProductContructionInfo,
    StatusComponent: VitecNextOrderItemStatusConstructionInfo,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: getConstructionInfoTextFromUpload,
    getJSON: ({ uploadedFiles = [] }) =>
      uploadedFiles
        .filter((f) => f.PropertyAdditionalInfo.ByggemåteStructured)
        .reduce((a, f) => {
          return {
            ...a,
            ByggemåteStructured: JSON.parse(f.PropertyAdditionalInfo.ByggemåteStructured || {}),
            TilstandsgradStructured: JSON.parse(f.PropertyAdditionalInfo?.TilstandsgradStructured || {})
          }
        }, {})
  },
  [VitecNextProduct.SHORT_LONG_DESCRIPTION]: {
    textKey: Texts.dashboardShortLongTitle,
    descriptionKey: Texts.dashboardShortLongDescr,
    imageKey: Images.headerAndShortDescriptionIcon,
    slug: 'short-and-long',
    PreviewComponent: VitecNextOrderProductLongShortDescription,
    StatusComponent: VitecNextOrderItemStatusLongAndShortDescription,
    enableFulfillment: false,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsSelectPinpointText,
        onClick: (props) =>
          createLeopardOrder({
            ...props,
            extra: {
              ...props.extra,
              longShortDescription: {
                longShortDescriptionType: 'bullets'
              }
            }
          })
      },
      {
        buttonTextKey: Texts.ImageTextsSelectFloatingText,
        onClick: (props) =>
          createLeopardOrder({
            ...props,
            extra: { ...props.extra, longShortDescription: { longShortDescriptionType: 'long' } }
          })
      }
    ]
  },
  [VitecNextProduct.VALIDATE_AD]: {
    textKey: Texts.dashboardAdValidationTitle,
    descriptionKey: Texts.dashboardAdValidationDescr,
    imageKey: Images.adValidationIcon,
    slug: 'ad-validation',
    buttons: [{ buttonTextKey: Texts.dashboardCtaGenerate, onClick: (o) => createLeopardOrder(o) }]
  },
  [VitecNextProduct.BASIC_ASSOCIATION]: {
    textKey: Texts.dashboardBasicAssociationTitle,
    descriptionKey: Texts.dashboardBasicAssociationDesc,
    imageKey: Images.basicAssiciationIcon,
    slug: 'basic-association',
    PreviewComponent: VitecNextOrderProductBasicAssociation,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: createLeopardOrder
      }
    ],
    getText: ({ leopardOrder }) => {
      const basicFields = Object.entries(leopardOrder?.extra?.basicAssociationFields || {}).reduce((a, [k, v]) => {
        return {
          ...a,
          [k]: {
            ...v,
            fulfilledAt: undefined,
            isFulfilling: undefined,
            checked: undefined,
            key: undefined
          }
        }
      }, {})

      return JSON.stringify(basicFields, null, 2)
    }
  },
  [VitecNextProduct.SELF_DECLERATION]: {
    textKey: Texts.SelfDeclerationSdOrderItemHeader,
    descriptionKey: Texts.SelfDeclerationSdOrderItemDescrition,
    DescriptionSuffixComponent: () => (
      <Box link>
        <FigmaText
          textKey={Texts.SelfDeclerationSdOrderItemDescrition}
          text="Ex link"
          anchorHref={FREMTIND_EX_PDF_URL}
        />
      </Box>
    ),
    imageKey: Images.adValidationIcon,
    slug: 'selfdecleration',
    PreviewComponent: VitecNextOrderProductSelfDeclaration,
    enableFulfillment: false,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) => {
      if (!jaguarResults) return ''
      const selfDeclerationResults = jaguarResults as SelfDeclarationResults
      return selfDeclerationResults.results.map((r) => `${r.question}\n${r.answer}\n\n`).join('')
    }
  },
  [VitecNextProduct.DESCRIPTIONS]: {
    textKey: HardCodedTexts.DescriptionsDOrderItemHeader,
    descriptionKey: Texts.DescriptionsDOrderItemDescription,
    imageKey: Images.standardIIcon,
    slug: 'descriptions',
    PreviewComponent: VitecNextOrderProductDescriptions,
    StatusComponent: VitecNextOrderItemStatusConstructionInfo,
    enableBetaUX: true,
    enableFulfillment: false,
    enableDiffInFeedbackView: false,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: (o) => createLeopardOrder(o)
      }
    ],
    getText: ({ jaguarResults }) => {
      if (!jaguarResults) return ''
      const selfDeclerationResults = jaguarResults as DescriptionsResults
      return selfDeclerationResults.message
    }
  }
}

import React from 'react'
import { ImageKey } from '../../../../pure/types/Antiloop'
import * as _Images from '../libs/ImagesRepository'
import * as ImagesDeprecated from '../libs/ImagesRepositoryDeprecated'

const DefaultImages = { ..._Images, ...ImagesDeprecated }

const FigmaImage: React.FC<{
  imageKey: ImageKey
  imageStyle?: { [name: string]: string | number | undefined }
  Images?: any
}> = ({ imageKey, imageStyle, Images = DefaultImages }) => {
  const { width, height } = imageKey
  const component = Images[imageKey.name]
  const style = { ...(!!imageStyle && imageStyle) }
  if (!component) return null

  if (imageKey.format === 'svg')
    return React.createElement(component, {
      style,
      alt: imageKey.name,
      width,
      height
    })

  return <img src={component} style={style} alt={imageKey.name} />
}

export default FigmaImage

/* eslint-disable react/display-name */
import { useEffect, useState } from 'react'
import useAppState, { State } from './useAppState'

type Query<T> = { data?: T; isLoading: boolean; error?: Error }

const DEFAULT_OPTIONS = { enabled: true, key: '' }
export const useAsyncFunction = <T>(
  fn: (state: State) => Promise<T>,
  { enabled = true, key = '' }: { enabled?: boolean; key?: string } = DEFAULT_OPTIONS
) => {
  const { state } = useAppState()
  const [query, setQuery] = useState<Query<T>>({ isLoading: false })
  useEffect(() => {
    if (!enabled) return
    Promise.resolve(setQuery({ ...query, isLoading: true }))
      .then(() => fn(state))
      .then((data) => setQuery({ ...query, data, isLoading: false, error: undefined }))
      .catch((err) => setQuery({ ...query, isLoading: false, error: err, data: undefined }))
  }, [enabled, key])

  return query
}

import React from 'react'
import Box from './Box'
import { local } from '../Config'

export function GoToLocalDevEnvironment() {
  return (
    <Box pointer onClick={() => window.open(local.appUrl + window.location.pathname)}>
      <Box visibility="hidden">{'Go To Local'}</Box>
    </Box>
  )
}


import {ReactComponent as regenerateTextImg} from './../assets/images/regenerateText.svg'
export const regenerateText = regenerateTextImg 
import {ReactComponent as warningIconImg} from './../assets/images/warningIcon.svg'
export const warningIcon = warningIconImg 
import {ReactComponent as roundCheckIconWhiteImg} from './../assets/images/roundCheckIconWhite.svg'
export const roundCheckIconWhite = roundCheckIconWhiteImg 
import {ReactComponent as gazelleInCircleImg} from './../assets/images/gazelleInCircle.svg'
export const gazelleInCircle = gazelleInCircleImg 
import pngDocIconImg from './../assets/images/pngDocIcon.png'
export const pngDocIcon = pngDocIconImg 
import {ReactComponent as imageCaptionIconImg} from './../assets/images/imageCaptionIcon.svg'
export const imageCaptionIcon = imageCaptionIconImg 
import {ReactComponent as headerAndShortDescriptionIconImg} from './../assets/images/headerAndShortDescriptionIcon.svg'
export const headerAndShortDescriptionIcon = headerAndShortDescriptionIconImg 
import {ReactComponent as constructionInfoIconImg} from './../assets/images/constructionInfoIcon.svg'
export const constructionInfoIcon = constructionInfoIconImg 
import {ReactComponent as areaDescriptionIconImg} from './../assets/images/areaDescriptionIcon.svg'
export const areaDescriptionIcon = areaDescriptionIconImg 
import {ReactComponent as adValidationIconImg} from './../assets/images/adValidationIcon.svg'
export const adValidationIcon = adValidationIconImg 
import {ReactComponent as energyDeclarationIconImg} from './../assets/images/energyDeclarationIcon.svg'
export const energyDeclarationIcon = energyDeclarationIconImg 
import {ReactComponent as standardIIconImg} from './../assets/images/standardIIcon.svg'
export const standardIIcon = standardIIconImg 
import {ReactComponent as basicAssiciationIconImg} from './../assets/images/basicAssiciationIcon.svg'
export const basicAssiciationIcon = basicAssiciationIconImg 
import pngFeedbackIconImg from './../assets/images/pngFeedbackIcon.png'
export const pngFeedbackIcon = pngFeedbackIconImg 
import {ReactComponent as smallFeedbackIconImg} from './../assets/images/smallFeedbackIcon.svg'
export const smallFeedbackIcon = smallFeedbackIconImg 
import {ReactComponent as uploadArrowImg} from './../assets/images/uploadArrow.svg'
export const uploadArrow = uploadArrowImg 
import {ReactComponent as uploadArrowWhiteImg} from './../assets/images/uploadArrowWhite.svg'
export const uploadArrowWhite = uploadArrowWhiteImg 
import {ReactComponent as uploadedArrowImg} from './../assets/images/uploadedArrow.svg'
export const uploadedArrow = uploadedArrowImg 
import {ReactComponent as copyWhiteImg} from './../assets/images/copyWhite.svg'
export const copyWhite = copyWhiteImg 
import {ReactComponent as copyBlackImg} from './../assets/images/copyBlack.svg'
export const copyBlack = copyBlackImg 
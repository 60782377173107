import React, { useContext } from 'react'
import { StorageKey, StorageType } from './useLocalStorageItemHelper'
import { useStorageItem } from './useStorageItem'

export type AppStateSession = {
  enablePreview?: boolean
}

export const DEFAULT_STATE: AppStateSession = {
  enablePreview: true
}

export type AppSessionContext = {
  state: AppStateSession
  setState: (state: AppStateSession) => unknown
}
export const AppSessionContext = React.createContext<AppSessionContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof AppStateSession> = []

export const useAppStateSessionContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS,
    storageType: StorageType.SESSION_STORAGE
  })

  return { state, setState }
}

export default function useAppStateSession() {
  return useContext(AppSessionContext)
}

/**
 * Format: int32
 * @description Importtypene som ønskes lagret
 *
 * 0 = None (Ingen verdier skal lagres)
 *
 * 1 = AccountingBudgeting (Regnskap/budsjett/kostnadsøkninger)
 *
 * 2 = AmountYearsInstallmentFree (Avdragsfrie år)
 *
 * 3 = Animals (Husdyrhold)
 *
 * 4 = BoardApproval (Styregodkjennelse)
 *
 * 5 = CommunityFee (Kommentar kommunale avgifter)
 *
 * 6 = ConstructionMethod (Byggemåte)
 *
 * 7 = GuaranteeFund (Sikringsordning fellesgjeld)
 *
 * 8 = InsuranceCompany (Borettslagets forsikringsselskap. MaxLength(255))
 *
 * 9 = InsurancePolicy (Polisenummer felles forsikring. MaxLength(255))
 *
 * 10 = JointCostAfterGracePeriod (Felleskostnader etter avdragsfri periode)
 *
 * 11 = JointDebtDescription (Kommentar fellesgjeld)
 *
 * 12 = JointDebtInstallment (Avdrag fellesgjeld)
 *
 * 13 = JointDebtInterest (Rentekostnader fellesgjeld)
 *
 * 14 = LoanTermsCollectiveDebt (Lånebetingelser fellesgjeld)
 *
 * 15 = PartAbout (Om boligselskapet (borettslaget, sameiet eller aksjeselskapet))
 *
 * 16 = Preemptive (Forkjøpsrett)
 *
 * 17 = RentIncludes (Felleskostnader inkluderer)
 *
 * 18 = ResidentCommitments (Beboernes forpliktelser og dugnader)
 *
 * 19 = Statutes (Vedtekter/husordensregler)
 *
 * 20 = ValuationType (Type rapport. MaxLength(255))
 *
 * 21 = EstateCorporationPartialBondNumber (Partialobligasjonsnummer)
 *
 * 22 = PartNumber (Andelsnummer)
 *
 * 23 = ShareJointDebtYear (Andel fellesgjeld år)
 *
 * 24 = ChangeOfOwnershipFee (Eierskiftegebyr)
 *
 * 25 = CollectiveDebt (Andel fellesgjeld)
 *
 * 26 = CommunityTax (Kommunale avgifter)
 *
 * 27 = Deposit (Innskudd)
 *
 * 28 = EstateCorporationPartialDenominatedBond (Partialobligasjon pålydende)
 *
 * 29 = EstimatedValue (Verditakst)
 *
 * 30 = LoanFare (Lånetakst)
 *
 * 31 = PreemptiveFee (Gebyr forkjøpsrett)
 *
 * 32 = RentPrMonth (Felleskostnader per måned)
 *
 * 33 = ShareJointCapital (Andel fellesformue)
 *
 * 34 = HasBoardApproval (Har styregodkjennelse)
 *
 * 35 = HasPreemptiveRight (Har forkjøpsrett)
 *
 * 36 = PreemptiveDeadline (Forkjøpsfrist)
 *
 * 37 = ShareJointCapitalDate (Andel felles formue per dato)
 *
 * 38 = ShareJointDebtToDate (Andel fellesgjeld per dato)
 *
 * 39 = ValuationDate (Rapportdato)
 * @enum {integer}
 */

export enum VitecNextImportType {
  None = 0,
  AccountingBudgeting = 1,
  AmountYearsInstallmentFree = 2,
  Animals = 3,
  BoardApproval = 4,
  CommunityFee = 5,
  ConstructionMethod = 6,
  GuaranteeFund = 7,
  InsuranceCompany = 8,
  InsurancePolicy = 9,
  JointCostAfterGracePeriod = 10,
  JointDebtDescription = 11,
  JointDebtInstallment = 12,
  JointDebtInterest = 13,
  LoanTermsCollectiveDebt = 14,
  PartAbout = 15,
  Preemptive = 16,
  RentIncludes = 17,
  ResidentCommitments = 18,
  Statutes = 19,
  ValuationType = 20,
  EstateCorporationPartialBondNumber = 21,
  PartNumber = 22,
  ShareJointDebtYear = 23,
  ChangeOfOwnershipFee = 24,
  CollectiveDebt = 25,
  CommunityTax = 26,
  Deposit = 27,
  EstateCorporationPartialDenominatedBond = 28,
  EstimatedValue = 29,
  LoanFare = 30,
  PreemptiveFee = 31,
  RentPrMonth = 32,
  ShareJointCapital = 33,
  HasBoardApproval = 34,
  HasPreemptiveRight = 35,
  PreemptiveDeadline = 36,
  ShareJointCapitalDate = 37,
  ShareJointDebtToDate = 38,
  ValuationDate = 39
}

import Box from './Box'
import { BIG_ICON_SIZE, CheckBoxBlankIcon, CheckBoxIcon, MEDIUM_ICON_SIZE } from './Icons'

export const Checkbox = ({ value }: { value: boolean }) => {
  return (
    <Box style={{ width: BIG_ICON_SIZE, height: BIG_ICON_SIZE }} align="center" justify="center">
      {value && <CheckBoxIcon size={MEDIUM_ICON_SIZE} />}
      {!value && <CheckBoxBlankIcon size={BIG_ICON_SIZE} />}
    </Box>
  )
}

import { UploadedFile } from '../types/JaguarTypes'

export const getConstructionInfoTextFromUpload = ({
  uploadedFiles = []
}: {
  uploadedFiles?: UploadedFile[] | undefined
}): string => {
  if (uploadedFiles.length === 0) return ''

  const file = uploadedFiles[0]

  const { Byggemåte: byggemate, Tilstandsgrad: tilstandsgrad } = (file.PropertyAdditionalInfo || {}) as any

  return `${byggemate}\nTillstandsgrad:\n\n${tilstandsgrad}`
}

import { onAuthStateChanged, User } from 'firebase/auth'
import React, { useEffect } from 'react'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { getUser } from '../libs/DBApiHandler'
import { captureException } from '../libs/ErrorHelper'
import { auth } from '../libs/Firebase'
import { signInWithCustomToken } from '../libs/FirebaseHelper'
import { slack, SlackWebhooks } from '../SlackHelper'
import useAppState from './useAppState'

type Props = {
  vitecNextOrder?: VitecNextOrder
  onFinally?: () => unknown
  enable?: boolean
  enableSignInWithOrder?: boolean
}

export default function useLoginVitecNextUser(props: Props) {
  const { vitecNextOrder, enable = true, enableSignInWithOrder = true } = props
  const { state, setState } = useAppState()
  const [loading, setLoading] = React.useState(true)
  const logout = () => console.log('Logged Out') // useLogout()

  const onLogin = (firebaseUser: User) => {
    setState({ ...state, firebaseUser })

    getUser(firebaseUser.uid).then((user) => {
      user && setState({ ...state, user })
    })

    slack(`:white_check_mark: Logged in as ${firebaseUser.email}`, SlackWebhooks.DEBUG)
  }

  useEffect(() => {
    if (!enable) return
    if (!vitecNextOrder?.authToken) return
    if (!enableSignInWithOrder) return

    signInWithCustomToken(vitecNextOrder?.authToken).catch(captureException)
  }, [vitecNextOrder?.authToken])

  React.useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) =>
        Promise.resolve()
          .then(() => {
            if (!user && !!state.user._id) return logout()
            if (!user) return

            console.log('Logged In', user)
            return Promise.resolve(setLoading(true))
              .then(() => onLogin(user))
              .finally(() => props.onFinally?.())
          })
          .catch(captureException)
          .finally(() => setLoading(false)),
      captureException
    )
  }, [])

  return loading
}

import styled from '@emotion/styled'
import { useEffect } from 'react'
import { BasicAssosciationResults } from '../../../../pure/leopard/JaguarTypesDerived'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import {
  BasicAssociationField,
  BasicAssociationFields,
  BasicAssociationResponseItemsKey,
  KeysThatCanBeCantBeUploaded,
  KeysThatCanBeUploaded
} from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { useBasicAssociaionFieldsState } from '../hooks/useBasicAssociaionFieldsState'
import { getJaguarResults } from '../libs/CloudFunctionsApiHandler'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { success } from '../libs/ToastHelper'
import {
  getBasicAssociationFieldsFromJaguarResult,
  shouldShowBasicAssociationField
} from '../libs/VitecNextOrderProductBasicAssociationHelper'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import Loader from './Loader'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductBasicAssociationItem } from './VitecNextOrderProductBasicAssociationItem'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'

export const VitecNextOrderProductBasicAssociation = (props: VitecNextOrderProductProps) => {
  const { state } = useAppState()
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading: isLoadingVitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const { requestId } = leopardOrder || {}

  const { data: upload } = useUpload(leopardOrder?.requestId || '')

  const { data: jaguarResult, isLoading: isLoadingJaguarResults } = useAsyncFunction<BasicAssosciationResults>(
    () => getJaguarResults({ requestId: leopardOrder?.requestId || '', vitecNextOrderId }, state),
    { enabled: !!requestId && upload?.status === 'done' }
  )

  const { basicAssociationFields, updateBasicAssociationFields } = useBasicAssociaionFieldsState(vitecNextOrderId)

  useEffect(() => {
    if (isLoadingJaguarResults === true) return
    const fields = jaguarResult?.results
    if (!fields) return

    updateBasicAssociationFields(getBasicAssociationFieldsFromJaguarResult(fields))
  }, [isLoadingJaguarResults])

  const isLoading = (isLoadingVitecNextOrder || isLoadingJaguarResults) && !basicAssociationFields

  if (!vitecNextOrder) return <Box />
  if (!leopardOrder) return <Box />

  const successText = getFigmaText(Texts.standardNotificationUploadComplete)

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <VitecNextOrderProductBackButton {...props} />
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <VitecNextOrderProductHeader
          vitecNextOrder={vitecNextOrder}
          headerTextKey={Texts.BasicAssociationBaHeaderText}
          vitecNextProduct={VitecNextProduct.BASIC_ASSOCIATION}
        />
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            vitecNextOrder={vitecNextOrder}
            vitecNextProduct={VitecNextProduct.BASIC_ASSOCIATION}
            fulfillMentSuccessText={successText}
            enableCopy={false}
            onPostFulfill={() => success(successText)}
            onPreFulfill={async () => {
              basicAssociationFields &&
                (await updateBasicAssociationFields(
                  Object.entries(basicAssociationFields).reduce((a, [k, v]) => {
                    if (v.checked === false) return a

                    return {
                      ...a,
                      [k]: {
                        ...v,
                        fulfilledAt: null,
                        isFulfilling: true
                      } as BasicAssociationField
                    }
                  }, {} as BasicAssociationFields)
                ))
            }}
          />
        </Box>
        <Box top />
        {upload?.status === 'processing' && <LoadingPage />}
        {upload?.status === 'done' && (
          <Box fullWidth>
            <Box fullWidth>
              <Container fullWidth fullPadding position="relative">
                <Box direction="row" align="center">
                  <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                </Box>
                {false && (
                  <Box top>
                    <VitecNextOrderProductFeedbackButton {...props} />
                  </Box>
                )}
                {isLoading && (
                  <Box fullWidth align="center" fullPadding>
                    <Loader />
                  </Box>
                )}
                {basicAssociationFields &&
                  KeysThatCanBeUploaded
                    //
                    .filter((key) => shouldShowBasicAssociationField({ itemKey: key, basicAssociationFields }))
                    .map((key, i) => {
                      return (
                        <Box top fullWidth>
                          <VitecNextOrderProductBasicAssociationItem
                            key={i}
                            basicAssociationFields={basicAssociationFields}
                            setBasicAssociationFields={updateBasicAssociationFields}
                            itemKey={key as BasicAssociationResponseItemsKey}
                            leopardOrder={leopardOrder}
                            vitecNextOrder={vitecNextOrder}
                          />
                        </Box>
                      )
                    })}
              </Container>
            </Box>
            <Box fullWidth top>
              <Container fullWidth fullPadding position="relative">
                <Box direction="row" align="center">
                  <FigmaText textKey={Texts.BasicAssociationSuggestedTextHeaderNoUpload} />
                </Box>
                {basicAssociationFields &&
                  KeysThatCanBeCantBeUploaded
                    //
                    .filter((key) => shouldShowBasicAssociationField({ itemKey: key, basicAssociationFields }))
                    .map((key, i) => {
                      return (
                        <Box top fullWidth>
                          <VitecNextOrderProductBasicAssociationItem
                            key={i}
                            basicAssociationFields={basicAssociationFields}
                            setBasicAssociationFields={updateBasicAssociationFields}
                            itemKey={key as BasicAssociationResponseItemsKey}
                            enableUpload={false}
                            vitecNextOrder={vitecNextOrder}
                            leopardOrder={leopardOrder}
                          />
                        </Box>
                      )
                    })}
              </Container>
            </Box>
          </Box>
        )}
      </Box>
      <Box visibility="hidden">
        <VitecNextOrderProductBackButton {...props} />
      </Box>
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import config from '../Config'
import { getFeatureFlagsFromUrl } from '../libs/Common'
import * as QueryHooks from './QueryHooks'

const featureFlagEnvironment = config.name

const featureFlagsFromUrl = getFeatureFlagsFromUrl()

export const useFeatureFlag = (flag: FeatureFlag): boolean | undefined => {
  const { data: featureFlags } = QueryHooks.useFeatureFlags(featureFlagEnvironment)
  const { data: defaultFlags } = QueryHooks.useFeatureFlags('default')

  if (featureFlagsFromUrl[flag]) return true

  if (featureFlagEnvironment === 'local') return config[flag]

  if (typeof featureFlags?.[flag] === 'boolean') return featureFlags?.[flag]
  if (typeof defaultFlags?.[flag] === 'boolean') return defaultFlags?.[flag]

  return false
}

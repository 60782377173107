import { BasicAssociationResponseItemsKey } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { TextKey } from '../../../../pure/types/Antiloop'
import { Texts } from './Texts'

export const VitecNextOrderProductBasicAssociationItems: {
  [property in BasicAssociationResponseItemsKey]: {
    textKey?: TextKey
  }
} = {
  felleskostnader_pr_mnd: {
    textKey: Texts.BasicAssociationBaRentPrMonth
  },
  borettslaget: {
    textKey: Texts.BasicAssociationBaPartName
  },
  borettslagets_org_nr: {
    textKey: Texts.BasicAssociationBaPartOrgNumber
  },
  styregodkjennelse: {
    textKey: Texts.BasicAssociationBaBoardApproval
  },
  forkjøpsrett: {
    textKey: Texts.BasicAssociationBaPreemptive
  },
  husdyrhold: {
    textKey: Texts.BasicAssociationBaAnimal
  },
  lånebetingelser_fellesgjeld: {
    textKey: Texts.BasicAssociationBaLoanTermsCollectiveDebt
  },
  sikringsordning_fellesgjeld: {
    textKey: Texts.BasicAssociationBaGuaranteeFund
  },
  borettslagets_forsikringsselskap: {
    textKey: Texts.BasicAssociationBaInsuranceCompany
  },
  polisenummer_felles_forsikring: {
    textKey: Texts.BasicAssociationBaInsurancePolicy
  },
  forretningsfører: {
    textKey: Texts.BasicAssociationBaBusinessManager
  },
  eierskiftegebyr: {
    textKey: Texts.BasicAssociationBaChangeOfOwnershipFee
  },
  has_board_approval: {},
  has_preemptive: {}
}

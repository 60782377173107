import { collection, limit, orderBy, query } from 'firebase/firestore'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { VitecNextProductPureInfoItems } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Collections from '../../../../pure/libs/Collections'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import Box from '../components/Box'
import { VitecNextOrderProductDiff } from '../components/VitecNextOrderProductDiff'
import { useFirestoreQuery } from '../hooks/useFirestoreQuery'
import { db } from '../libs/Firebase'
import dayjs from 'dayjs'

const ExperimentalProducts = Object.values(VitecNextProductPureInfoItems)
  .map((v) => v.vitecNextProductExperimental)
  .filter((v) => !!v)
  .reduce((acc, v) => {
    if (!v) return acc
    return { ...acc, [v as string]: true }
  }, {})

export const VitecNextOrderProductAllDiffsPage = () => {
  const { data: vitecNextOrders = [] } = useFirestoreQuery<VitecNextOrder[]>(
    query(collection(db, Collections.VITEC_NO_ORDER), orderBy('orderPlaced', 'desc'), limit(100)),
    { fnName: 'VitecNextOrderProductAllDiffsPage' }
  )

  const leopardOrders = vitecNextOrders
    .filter((order) =>
      Object.keys(order.jaguarResponses || {}).some(
        (key) => !!VitecNextProductPureInfoItems[key].vitecNextProductExperimental
      )
    )
    .map((vitecNextOrder) =>
      Object.entries(vitecNextOrder.jaguarResponses || {}).map(([key, value]) => ({ value, key, vitecNextOrder }))
    )
    .flatMap((v) => v)
    .filter(({ key }) => !ExperimentalProducts[key])
    .sort((a, b) => dayjs(b.value.createdAt).valueOf() - dayjs(a.value.createdAt).valueOf())

  return (
    <Box fullWidth>
      {leopardOrders.map(({ vitecNextOrder, key }) => (
        <Box fullWidth>
          <Box fullWidth>{vitecNextOrder.address}</Box>
          <Box top>
            <VitecNextOrderProductDiff
              vitecNextOrderId={vitecNextOrder._id}
              vitecNextProduct={key as VitecNextProduct}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

import React from 'react'
import styled from '@emotion/styled'
import { ImageKey } from '../../../../pure/types/Antiloop'

import FigmaImage from './FigmaImage'
import Loader from './Loader'

const FigmaImageContainer: React.FC<{
  imageKey: ImageKey
  onClick?: () => void
  className?: string
  imageStyle?: { [name: string]: string | number }
  fullWidth?: boolean
  isLoading?: boolean
  width?: number
  Images?: any
}> = (props) => {
  const { imageKey, fullWidth, imageStyle, className, onClick, isLoading, Images } = props
  const { width, height } = imageKey
  const aspectRatio = width / height
  const _width = fullWidth ? '100%' : props.width || width
  const _height = _width / aspectRatio

  if (isLoading) return <Loader size={width} />
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      style={{
        maxWidth: _width,
        maxHeight: _height
      }}
    >
      {imageKey && (
        <FigmaImage
          imageKey={imageKey}
          imageStyle={{ ...imageStyle, aspectRatio: aspectRatio.toString(), width: _width, height: _height }}
          Images={Images}
        />
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
`
export default FigmaImageContainer

import Colors from '../assets/colors.json'

export const SecondaryColor = '#0052B4'

export const Yellow = '#F1DE7C'
export const LightYellow = '#FFFADE'

export const LightGrey = '#E3E3E3'

export const SuccessColor = '#088E1A'

export const RedRed = '#FF6262' //
export const Purple = '#CB2E72'

export const EmptyState = 'rgba(241, 222, 124, 0.3)'

export const EmptyStateBorder = '#D1BC52'

export default Colors

import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import Colors from '../../../../pure/libs/Colors'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import { Layout, ContainerWithGradient } from './Layout'
import { SignInLandingCards } from './SignInLandingCards'
import { SignInLandingSignInForm } from './SignInLandingSignInForm'
import { SIGN_IN_LANDING_FIGMA_HEIGHT } from '../libs/HardCodedSizes'
import { Helmet } from './Helmet'

export const SignInLandingLoginFormWidth = 550
export default function SignInLanding(props: SignInViewProps) {
  return (
    <Layout enableFooter={false} menuZIndex={ZIndices.high} Container={LayoutContinaner}>
      <Helmet title="Login" />
      <ContainerWithGradient fullWidth fullHeight direction="row">
        <Box position="absolute" zIndex={ZIndices.regular}>
          <SignInLandingCards />
        </Box>
        <Box fullWidth />
        <Box fullWidth backgroundColor={Colors.primary100} align="center" top spacing={reallyBigSpacing}>
          <Box width={`${SignInLandingLoginFormWidth}px`}>
            <SignInLandingSignInForm {...props} />
          </Box>
        </Box>
      </ContainerWithGradient>
    </Layout>
  )
}

export const LayoutContinaner = styled(Box)`
  min-height: ${SIGN_IN_LANDING_FIGMA_HEIGHT}px;
  height: 100vh;
  background-color: ${Colors.primary100};
  overflow-x: hidden;
  overflow-y: hidden;
`

export const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background-color: ${Colors.primary80};
`

import styled from '@emotion/styled'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { Texts } from '../../../../pure/libs/AppTexts'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import Box from './Box'
import LoginWithEmailForm from './SignInWithEmailForm'
import { Container } from './SignInLandingExperimental'
import { SignInLandingUpsell } from './SignInLandingUpsellExperimental'
import { SignInWithAuthProviders } from './SignInWithAuthProviders'
import { SIGN_IN_LANDING_FIGMA_HEIGHT } from '../libs/HardCodedSizes'

export function SignInLandingSignInForm(props: SignInViewProps) {
  return (
    <OuterContainer fullWidth align="center">
      <Box fullWidth>
        <SignInLandingUpsell />
        <Box fullWidth align="center" top>
          <Box fullWidth>
            <SignInWithAuthProviders {...props} />
          </Box>
          <Box fullWidth fullPadding></Box>
          <Container top fullWidth fullPadding>
            <Box fullWidth alignText="center">
              <FigmaText textKey={Texts.gazelleLandingOrUseMail} />
            </Box>
            <LoginWithEmailForm {...props} />
          </Container>
        </Box>
      </Box>
    </OuterContainer>
  )
}

export const OuterContainer = styled(Box)`
  min-height: ${SIGN_IN_LANDING_FIGMA_HEIGHT}px;
  background-color: ${Colors.primary100};
`

import { VitecNextProduct } from './VitecNextProduct.js'

export type IsVitecNextProductEnabledResponse = {
  vitecNextProduct: VitecNextProduct
  isEnabled: boolean
  noOfDocuments: number
  code?: IsVitecNextProductEnabledResponseCode
  extra?: {
    bytes?: number
    url?: string
  }
}

export enum IsVitecNextProductEnabledResponseCode {
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  DOCUMENT_TOO_BIG = 'DOCUMENT_TOO_BIG'
}

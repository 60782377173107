import styled from '@emotion/styled'
import { Popper } from '@mui/material'
import MaterialTooltip, { TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import FigmaText from './FigmaText'
import Colors from '../../../../pure/libs/Colors'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { TextKey } from '../../../../pure/types/Antiloop'

type Props = TooltipProps & {
  title: string
  titleElement?: React.ReactElement
  enableNoLineHeight?: boolean
  backgroundColor?: string
  textKey?: TextKey
}
const Tooltip: React.FC<Props> = (props) => {
  const {
    titleElement,
    enableNoLineHeight = true,
    textKey = TextsDeprecated.allTooltipText,
    backgroundColor,
    ...rest
  } = props
  if (props.title === '') return props.children
  return (
    <MaterialTooltip
      {...rest}
      title={titleElement || <FigmaText textKey={textKey} text={props.title} />}
      PopperComponent={(props) => <StyledPopper {...props} $backgroundColor={backgroundColor} />}
    >
      <div
        style={{
          width: '100%',
          lineHeight: enableNoLineHeight
            ? 0
            : undefined /* without line height 0, you get misplaced info icon when info icon is used in with tooltip*/
        }}
      >
        {props.children}
      </div>
    </MaterialTooltip>
  )
}

const StyledPopper = styled(Popper)<{ $backgroundColor?: string }>`
  .MuiTooltip-tooltip {
    background-color: ${({ $backgroundColor = Colors.primary100 }) => $backgroundColor};
  }
`

export default Tooltip

import { CollectionReference, DocumentData, DocumentReference, onSnapshot, Query, queryEqual } from 'firebase/firestore'
import { useEffect, useReducer } from 'react'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection.js'
import { Options, reducer, log, MISSING_PERMISSIONS, ReducerState } from './useFirestoreQueryHelper.js'
import useMemoCompare from './useMemoCompareDeprecated.js'
import { mapQueryResponse } from '../../../../pure/libs/FirebaseStorageClientBaseHelper.js'

type FirebaseQuery = Query<DocumentData> | CollectionReference<DocumentData> | DocumentReference<DocumentData>

export default function useFirestoreQueryDeprecated<T>(
  query: FirebaseQuery,
  fnName: string,
  mapFn?: (item: any) => any, // should be used in complex migrations only
  enabled = true
) {
  return useFirestoreQuery<T>(query, { fnName, mapFn, enabled })
}

// Hook
export function useFirestoreQuery<T>(query: FirebaseQuery, opts: Options) {
  // Our initial state
  // Start with an "idle" status if query is falsy, as that means hook consumer is
  // waiting on required data before creating the query object.
  // Example: useFirestoreQueryDeprecated(uid && firestore.collection("profiles").doc(uid))
  const { fnName, mapFn, enabled = true } = opts
  const initialState = {
    status: query ? 'loading' : 'idle',
    data: undefined,
    error: undefined,
    isLoading: enabled
  }
  // Setup our state and actions
  const [state, dispatch] = useReducer(reducer, initialState)
  // Get cached Firestore query object with useMemoCompare (https://usehooks.com/useMemoCompare)
  // Needed because firestore.collection("profiles").doc(uid) will always being a new object reference
  // causing effect to run -> state change -> rerender -> effect runs -> etc ...
  // This is nicer than requiring hook consumer to always memoize query with useMemo.
  const queryCached: FirebaseQuery = useMemoCompare(query, (prevQuery: FirebaseQuery) => {
    if (!prevQuery || !query) return false
    // Use built-in Firestore isEqual method to determine if "equal"

    if (['collection', 'query'].includes(query.type))
      return queryEqual(prevQuery as Query<DocumentData>, query as Query<DocumentData>)
    if (query.type === 'document')
      return (prevQuery as DocumentReference<DocumentData>).path === (query as DocumentReference<DocumentData>).path

    return false
  })

  useEffect(() => {
    // Return early if query is falsy and reset to "idle" status in case
    // we're coming from "success" or "error" status due to query change.
    if (!queryCached) {
      dispatch({ type: 'idle' })
      return
    }

    if (!enabled) return
    dispatch({ type: 'loading' })
    // Subscribe to query with onSnapshot
    // Will unsubscribe on cleanup since this returns an unsubscribe function
    return onSnapshot(
      queryCached as any,
      (response) => {
        // Get data for collection or doc
        const data = mapQueryResponse(response, mapFn)
        log('Firebase Success', fnName)

        dispatch({ type: 'success', payload: data })
      },
      (error) => {
        console.log('Firebase Error', fnName, error.message)
        if (!error.message.includes(MISSING_PERMISSIONS))
          onUnhandledPromiseRejection(new Error(`${error.message}: ${fnName}`))
        dispatch({ type: 'error', payload: error })
      }
    )
  }, [queryCached, enabled]) // Only run effect if queryCached changes
  return state as ReducerState<T>
}

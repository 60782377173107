import styled from '@emotion/styled'
import { EmptyState, EmptyStateBorder } from '../../../../pure/libs/Colors'
import { BasicAssociationResponseItemsKey } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { smallSpacing } from '../enums/Spacings'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { VitecNextOrderProductBasicAssociationItems } from '../libs/VitecNextOrderProductBasicAssociationItems'
import Box from './Box'
import FigmaText from './FigmaText'

export function VitecNextOrderProductBasicAssociationItemEmptyState({
  itemKey
}: {
  itemKey: BasicAssociationResponseItemsKey
}) {
  const item = VitecNextOrderProductBasicAssociationItems[itemKey]

  if (!item.textKey) return null

  return (
    <Container fullWidth>
      <Box left spacing={smallSpacing}>
        <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={item.textKey} />
        <Box fullWidth>
          <FigmaText
            textKey={Texts.BasicAssociationBaEmptyStateText}
            text={formatText(getFigmaText(Texts.BasicAssociationBaEmptyStateText), [getFigmaText(item.textKey)])}
          />
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${EmptyState};
  border: 1px dashed ${EmptyStateBorder};
`

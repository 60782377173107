import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { Texts as AppTexts } from '../../../../pure/libs/AppTexts'
import Colors from '../../../../pure/libs/Colors'
import { formatRoutePathVitexNextOrder } from '../../../../pure/libs/RoutePath'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { updateUploadedFile } from '../libs/DBApiHandler'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { LoadingPage } from './LoadingPage'
import { NoWrapFigmaText } from './StyledComponents'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductImageCaptionsResult } from './VitecNextOrderProductImageCaptionsResult'

export const VitecNextOrderProductImageCaptions = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId } = props
  const navigate = useNavigate()
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.IMAGE_CAPTIONS
  const uploadId = leopardOrder?.requestId

  const { data: upload } = useUpload(uploadId)
  const { data: uploadedFiles = [] } = useUploadedFiles(upload?.id)
  const uploadedFilesChecked = uploadedFiles.filter((file) => file?.metaData?.checked !== false)

  const [checked, setChecked] = React.useState(true)

  const onClickSelectAll = () => {
    if (!upload) return
    const newValue = !checked
    setChecked(newValue)
    uploadedFiles.forEach((file) => {
      updateUploadedFile(
        {
          ...file,
          metaData: { ...file?.metaData, checked: newValue }
        } as UploadedFile,
        upload
      )
    })
  }

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <VitecNextOrderProductBackButton {...props} />
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <VitecNextOrderProductHeader
          vitecNextOrder={vitecNextOrder}
          headerTextKey={Texts.ImageTextsItPreviewHeader}
          vitecNextProduct={VitecNextProduct.IMAGE_CAPTIONS}
        />
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            fulfillMentSuccessText={formatText(getFigmaText(Texts.ImageTextsNotificationItCompleted), [
              uploadedFilesChecked.length
            ])}
            vitecNextOrder={vitecNextOrder}
            vitecNextProduct={VitecNextProduct.IMAGE_CAPTIONS}
            enableCopy={false}
            SuffixComponent={() => (
              <Box right pointer onClick={() => onClickSelectAll()}>
                <Box direction="row" align="center">
                  <NoWrapFigmaText
                    styleTextKey={Texts.ImageTextsItCssImageText}
                    textKey={AppTexts.cationsSelectAllButton}
                  />
                  <Box left spacing={smallSpacing}>
                    <Checkbox value={checked} />
                  </Box>
                </Box>
              </Box>
            )}
          />
        </Box>
        {uploadedFiles.length > 0 && (
          <Box top>
            <FigmaText
              textKey={Texts.ImageTextsItPreviewSummary}
              text={formatText(getFigmaText(Texts.ImageTextsItPreviewSummary), [uploadedFiles.length])}
            />
          </Box>
        )}
        <Box top spacing={tinySpacing} />
        {upload?.status === 'processing' && uploadedFiles.length === 0 && <LoadingPage />}
        {uploadedFiles.length > 0 && (
          <Container align="center" fullWidth>
            <Box direction="row" style={{ flexWrap: 'wrap' }} fullPadding fullWidth justify="center">
              {uploadedFiles?.map((file, key) => {
                return (
                  <Box key={key} width="50%" fullPadding spacing={smallSpacing}>
                    <VitecNextOrderProductImageCaptionsResult file={file} upload={upload} index={key} />
                  </Box>
                )
              })}
            </Box>
          </Container>
        )}
      </Box>
      <Box />
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

const StyledCheckboxIcon = styled(CheckBoxIcon)`
  color: ${Colors.white};
`

import Images from '../../../../pure/libs/AppImages'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { SignInViewProps } from '../../../../pure/types/SignInTypes'
import config from '../Config'
import { smallSpacing } from '../enums/Spacings'
import Box from './Box'
import ContinueWithButton from './ContinueWithButton'

export function SignInWithAuthProviders(signInViewProps: SignInViewProps) {
  const { onLoginWithGoogle, onLoginWithMicrosoft, signInState } = signInViewProps

  const showMicrosoft = config.enableOffice365Login

  return (
    <Box fullWidth>
      <Box fullWidth>
        <ContinueWithButton
          onLogin={onLoginWithGoogle}
          textKey={TextsDeprecated.onboardingAuthProviderGoogleButtonText}
          imageKey={Images.googleIconSmall}
        />
      </Box>
      {showMicrosoft && (
        <Box top fullWidth spacing={smallSpacing}>
          <ContinueWithButton
            onLogin={onLoginWithMicrosoft}
            textKey={TextsDeprecated.onboardingAuthProviderMicrosoftButtonText}
            imageKey={Images.office365}
          />
        </Box>
      )}
    </Box>
  )
}
